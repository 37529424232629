import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapper: {
    backgroundColor: "white",
    borderRadius: "10px",
  },
  header: {
    padding: "10px 20px",
  },
  toolbar: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerToolbar: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  crudButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    padding: "10px 20px",
    minHeight: "500px",
  },
  datePicker: {
    "& input": { padding: "0 12px", height: "36px" },
  },
  spacing: {
    marginRight: "12px",
  },
}));
