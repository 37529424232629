import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import styles from "./styles";
import "../styles.css";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import DeviceConnection from "../../../../../Common/TableCellComponents/DeviceConnection";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { handleServerErrors, handleMultiFilterSearch } from "../../utiles/helpers";
import { useSelector } from "react-redux";
const filterLabels = {
  vm_name: "vm_name",
  serial_number: "serial_number",
  device_id: "oro_id",
  operator: "company",
  location: "location_name",
  area: "area_name",
};

const deviceTypeFields = ["vm_name", "serial_number", "device_id", "operator", "location", "area"];

const FinancialManagement = (props) => {
  const classes = styles();
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-status");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const current_user = useSelector((state) => state.userReducer.current_user);

  const fields = [
    {
      key: "operations",
      columnName: "Operations",
      type: "text",
      form: false,
      visible: true,
      render: (_, value) => (
        <>
          <Button
            size="small"
            className="mr-2 mb-2 mt-2"
            variant="contained"
            color="primary"
            onClick={() =>
              history.push({
                // pathname: `/finance/orders/${value.oro_id}`,
                pathname: `/finance/orders/${value.id}`,
                state: {
                  serial_number: value.serial_number,
                  vm_name: value.vm_name,
                },
              })
            }
          >
            Orders
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() =>
              history.push({
                pathname: `/finance/transactions/${value.id}`,
                state: {
                  serial_number: value.serial_number,
                  vm_name: value.vm_name,
                },
              })
            }
          >
            Transactions
          </Button>
        </>
      ),
    },
    {
      key: "serial_number",
      columnName: "VM Serial No.",
      label: "VM Serial No.",
      type: "text",
      visible: true,
      render: (value) => value || "---"
    },
    {
      key: "oro_id",
      columnName: "Device Id",
      visible: true,
      render: (value) => value || "---"
    },
    {
      key: "vm_name",
      columnName: "VM Name",
      label: "VM Name",
      type: "text",
      visible: true,
      render: (value) => value || "---"
    },
    {
      key: "customer_name",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: current_user.type === "FO" ? false : true,
      // visible: true,
      form: false,
      render: (_, value) => (value?.company?.business_name) || "---",
    },
    {
      key: "location_details",
      columnName: "Location",
      label: "Location",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---"
    },
    {
      key: "area_name",
      columnName: "Area",
      label: "Area",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---"
    },
    {
      key: "status",
      columnName: "Working Status",
      type: "text",
      required: false,
      visible: true,
      form: false,
      render: (value) => <DeviceConnection status={value} />,
    },
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data?.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getDeviceType = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page:  customPage + 1,
    };
    setup();
    const { data } = await window.axiosIns("/device", { params });
    handleRes(data?.data);
  };

  useEffect(() => {
    getDeviceType();
  }, []);

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/device", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
          }
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get device types. Try again."
          );
        }
      });
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("/device", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
        }
      })
      .then((data = {}) => {
        handleRes(data.data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Finance"
        description="
Manage finance of the vending machine."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}></div>
        <div className="d-flex">
          <SearchBox
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          sensorTable={true}
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(FinancialManagement);