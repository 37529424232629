import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import { TableGenerator } from "../../../../../Common";
import moment from "moment";
import {
  Grid,
  Typography,
  Card,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useParams, useLocation, useHistory } from "react-router-dom";
import CustomInputDatePicker from "components/Common/CustomInputDatePicker";
import { CloudDownload } from "@material-ui/icons";
import { handleMultiFilterSearch, downloadBlob } from "helpers";
import {
  getFilterStartDate,
  getFilterEndDate,
  getTimeZoneDifference,
} from "utils/helpers";
import _ from "lodash";
import { useSelector } from "react-redux";
import {
  convertUtcToLocal,
  dateFormatter,
  currencyFormatter,
} from "utils/helpers";

const filterLabels = {
  payment_id: "transaction_id",
  order_id: "order_id",
  transaction_id: "payment_id",
};

const deviceTypeFields = ["payment_id", "order_id", "transaction_id"];

const MonthlyStatement = () => {
  const classes = styles();
  const { deviceId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [deviceTypeList, setDeviceList] = useState([]);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [ordering, setOrdering] = useState("-timestamp");
  const [startDate, setStartDate] = useState(
    moment().subtract(15, "days").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [nextPage, setNextPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState({});
  const [exportLoader, setExportLoader] = useState(false);
  const current_user = useSelector((state) => state.userReducer.current_user);
  const fields = [
    {
      key: "transaction_id",
      columnName: "Vending Payment Id",
      type: "text",
      visible: true,
    },
    {
      key: "order_id",
      columnName: "Order Id",
      type: "text",
      visible: true,
    },
    {
      key: "payment_id",
      columnName: "Transaction Id",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "status",
      columnName: "Transaction Status",
      type: "text",
      visible: true,
    },
    {
      key: "gateway",
      columnName: "Gateway",
      type: "text",
      visible: true,
    },
    {
      key: "amount",
      columnName: "Amount ($)",
      type: "number",
      visible: true,
      render: (value) => currencyFormatter(value) ?? "---",
    },
    {
      key: "order_date",
      columnName: "Transaction time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true) ?? "---",
    },
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data = []) => {
    setLoader(false);
    setDeviceList(data?.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setCount(data.count);
  };

  useEffect(() => {
    fetchTransaction(ordering, page, limit);
  }, [startDate, endDate]);

  const changePage = (url) => {
    setup();
    window.axiosIns
      .get(url)
      .then(({ data = {} }) => {
        handleRes(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("transactions", {
          params: { ...searchFilter, limit: rowsPerPage, filter_device_id: deviceId, },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("transactions", {
        params: { ...searchFilter, limit: rowsPerPage, filter_device_id: deviceId },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const fetchTransaction = async (order, pageNumber, limit) => {
    setLimit(limit);
    const realPage = pageNumber ? 1 : page + 1;
    const params = {
      ...searchQuery,
      ordering: order,
      page: realPage,
      limit: limit,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      history: true,
      latest: true,
      _scope: "AND",
      filter_device_id: deviceId,
    };
    setLoader(true);

    try {
      const { data } = await window.axiosIns("transactions", {
        params,
      });

      setCount(data.count);
      handleRes(data);
      setLoader(false);
    } catch (err) {
      setLoader(false);
    }
  };

  const fetchExcel = async () => {
    setExportLoader(true);
    const params = {
      ...searchQuery,
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      tz: getTimeZoneDifference(),
      _scope: "AND",
      swap_payment_id: true,
      filter_device_id: deviceId,
    };

    try {
      const { data } = await window.axiosIns(
        "transactions/export_transaction_summary",
        {
          responseType: "blob",
          params,
        }
      );

      if (data) {
        downloadBlob(
          new Blob([data], { type: "application/xlsx" }),
          "transactions_report.xlsx"
        );
      }

      setExportLoader(false);
    } catch (err) {
      setExportLoader(false);
    }
  };

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="
        All transactions automatically show up here. You can filter that statements."
      />
      <Card
        style={{ margin: "0px 20px 10px 20px" }}
        variant="contained"
        className={classes.headerToolbar}
      >
        <Grid style={{ padding: "10px 20px" }} container>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">
              <strong>VM Serial : </strong>
              {location?.state?.serial_number || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle2">
              <strong>VM Name : </strong>
              {location?.state?.vm_name || ""}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <div className={classes.toolbar}>
        <Grid
          spacing={2}
          style={{ alignItems: "center" }}
          container
          className="pr-4 align-center"
        >
          <Grid style={{ marginRight: "8px" }}>
            <Button
              style={{ minWidth: 100, margin: "0 12px" }}
              size="small"
              className="text-red mr-1"
              color="primary"
              variant="contained"
              onClick={() =>
                history.push("/finance?tab=orders_and_transactions")
              }
            >
              Close
            </Button>
            {current_user.type !== "SU" && deviceTypeList.length >= 0 && (
              <Button
                style={{ minWidth: 100 }}
                size="small"
                color="primary"
                variant="contained"
                disabled={exportLoader || !count}
                onClick={fetchExcel}
              >
                {exportLoader ? (
                  <>
                    <CircularProgress
                      color="#fff"
                      size={16}
                      style={{ marginRight: 0 }}
                    />
                  </>
                ) : (
                  <>
                    <CloudDownload className="mr-1" fontSize="small" />{" "}
                  </>
                )}
                Export
              </Button>
            )}
          </Grid>
          <Grid item md={2} xs={3}>
            <CustomInputDatePicker
              inputClassName=""
              className={classes.datePicker + " " + classes.spacing}
              value={startDate}
              onChange={(date) => {
                setStartDate(date);
                setPage(0);
              }}
              label="Start Date"
              maxDate={new Date(endDate).setDate(new Date(endDate).getDate())}
              disabled={loader}
            />
          </Grid>

          <Grid item md={2} xs={3}>
            <CustomInputDatePicker
              inputClassName=""
              className={classes.datePicker}
              value={endDate}
              onChange={(date) => {
                setEndDate(date);
                setPage(0);
              }}
              label="End Date"
              minDate={new Date(startDate)}
              maxDate={new Date()}
              disabled={loader}
            />
          </Grid>

          <Grid item xl={4} md={2} xs={3}>
            <SearchBox
              multiple={true}
              query={query}
              onChange={handleFilter}
              fields={deviceTypeFields}
              selectedFields={filterable}
              handleSearch={handleSearch}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          data={deviceTypeList}
          loader={loader}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            fetchTransaction(ordering, page, limit);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            setRowsPerPage(rows);
            setLimit(rows);
            setPage(0);
            fetchTransaction(ordering, page, rows);
          }}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          dataCount={count}
          onChangePage={(page) => console.log(page)}
          onChangeSelected={(selectedInterface) =>
            setModulesSelected(selectedInterface)
          }
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(MonthlyStatement);
