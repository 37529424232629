import React from "react";
import UsersApp from "./Users";
import RolesApp from "./UserRoles/UserRoles"
import { TabsGenerator } from "../../Common/";

const tabs = [
  {
    label: "Users",
    value: "users",
    component: <UsersApp />,
  },
  {
    label: "Roles",
    value: "roles",
    component: <RolesApp />,
  },
];

const Users = () => {
  return (
    <>
      <TabsGenerator tabsList={tabs} activeTabs="users" />
    </>
  );
};

export default Users;
