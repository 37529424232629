import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import "./styles.css";
import {
  AddButton,
  EditButton,
  DeleteButton,
  AllocateButton,
  UnAllocateButton,
} from "../common/Buttons";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import _flatten from "lodash/flatten";
import CrudDialog from "../common/CrudDialog";
import CrudDialog1 from "../common/CrudDialog/index copy";
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";
import { useSnackbar } from "notistack";
import {
  handleServerErrors,
  handleMultiFilterSearch,
  objectDifference,
} from "../../utiles/helpers";
import { dateFormatter } from "utils/helpers";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));
function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow placement="top" classes={classes} {...props} />;
}

const filterLabels = {
  theme_id: "id",
  theme_name: "name",
  operator: "operator_id__business_name",
  // layout: "layout"
};

const deviceTypeFields = ["theme_id", "theme_name", "operator"];

const ThemeApp = (props) => {
  const classes = styles();
  const [addModal, setAddModal] = useState(false);
  const [deviceTypeList, setDeviceList] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [allocateModal, setAllocateModal] = useState(false);
  const [UnassignModal, setUnAssignModal] = useState(false);
  const [addeviceList, setAdDeviceList] = useState([]);
  const [hideAllocateModal, setHideAllocateModal] = useState(false);
  const [typeSelection, setTypeSelection] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [deviceList, setDevicesList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false);
  const current_user = useSelector((state) => state.userReducer.current_user);
  const enable_product_dropdown = useSelector(
    (state) => state.productsSettingsReducer.enable_product_dropdown
  );

  const allocateFields = [
    {
      key: "device_id",
      label: "Vending Machine",
      columnName: "Vending Machine",
      type: "multiAutoComplete",
      show: true,
      freeSolo: true,
      required: true,
      options: _.map(
        deviceList.filter(
          (x) => !addeviceList?.map((x) => x.value).includes(x?.id)
        ),
        (x) => ({
          label: `${x.vm_name} || (${x.serial_number})`,
          value: x?.oro_id,
        })
      ),
      visible: true,
    },
    // {
    //   key: "device_id",
    //   label: "Vending Machine(s)",
    //   visible: true,
    //   type: "multiAutoComplete",
    //   multiple: true,
    //   show: true,
    //   options: deviceList,
    // },
  ];

  const UnassignDialogFields = [
    {
      key: "devices",
      label: "Vending Machine(s)",
      visible: true,
      type: "multiAutoComplete",
      multiple: true,
      show: true,
      loading: true,
      required: false,
      extraProps: {
        filterSelectedOptions: true,
      },
      options: addeviceList,
    },
  ];

  const fields = [
    {
      key: "id",
      columnName: "ID",
      label: "ID",
      type: "text",
      visible: true,
      form: false,
    },
    [
      {
        key: "name",
        columnName: "Theme Name",
        label: "Theme Name",
        type: "text",
        visible: true,
        required: true,
      },
      {
        key: "operator_name",
        columnName: "Operator",
        label: "Operator",
        type: "text",
        visible: true,
        required: true,
        render: (value) => value ?? "---",
      },
      {
        key: "primary_color",
        columnName: "Primary color",
        label: "Primary color",
        value: "#000000",
        type: "color",
        visible: true,
        render: (_, values) =>
          values.primary_color && (
            <BootstrapTooltip title={values.primary_color}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 4,
                  backgroundColor: values.primary_color,
                }}
              ></div>
            </BootstrapTooltip>
          ),
      },
    ],
    [
      {
        key: "secondary_color",
        columnName: "Text Color",
        label: "Text Color",
        value: "#000000",
        type: "color",
        visible: true,
        render: (_, values) =>
          values.secondary_color && (
            <BootstrapTooltip title={values.secondary_color}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 4,
                  backgroundColor: values.secondary_color || "#000000",
                }}
              ></div>
            </BootstrapTooltip>
          ),
      },
      {
        key: "text_button_color",
        columnName: "Button Text Color",
        label: "Button Text Color",
        value: "#000000",
        type: "color",
        visible: true,
        render: (_, values) =>
          values.text_button_color && (
            <BootstrapTooltip title={values.text_button_color}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: 4,
                  backgroundColor: values.text_button_color,
                }}
              ></div>
            </BootstrapTooltip>
          ),
      },
      {
        key: "logo_url",
        columnName: "Logo",
        width: 100,
        label: "Logo",
        type: "file",
        form: true,
        disableSorting: true,
        visible: true,
        render: (_, values) =>
          values.logo_url && (
            <div
              style={{
                height: 64,
                width: 84,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${values.logo_url})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                }}
              ></div>
            </div>
          ),
      },
    ],
    [
      {
        key: "background_img_url",
        columnName: "Background Image",
        width: 100,
        label: "Background Image",
        type: "file",
        form: true,
        disableSorting: true,
        visible: true,
        render: (_, values) =>
          values.background_img_url && (
            <div
              style={{
                height: 64,
                width: 64,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${values.background_img_url})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                }}
              ></div>
            </div>
          ),
      },
      {
        key: "item_background_img_url",
        columnName: "Item Background",
        width: 100,
        label: "Item Background",
        type: "file",
        form: true,
        disableSorting: true,
        visible: true,
        render: (_, values) =>
          values.item_background_img_url && (
            <div
              style={{
                height: 64,
                width: 64,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${values.item_background_img_url})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                }}
              ></div>
            </div>
          ),
      },
      {
        key: "category_bg_image",
        columnName: "Category Background",
        width: 100,
        label: "Category Background",
        type: "file",
        form: true,
        disableSorting: true,
        visible: true,
        render: (_, values) =>
          values.category_bg_image && (
            <div
              style={{
                height: 64,
                width: 64,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${values.category_bg_image})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                }}
              ></div>
            </div>
          ),
      },
      {
        key: "dispensing_bg_image",
        columnName: "Dispensing Background",
        width: 100,
        label: "Dispensing Background",
        type: "file",
        form: true,
        disableSorting: true,
        visible: true,
        render: (_, values) =>
          values.dispensing_bg_image && (
            <div
              style={{
                height: 64,
                width: 64,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${values.dispensing_bg_image})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                }}
              ></div>
            </div>
          ),
      },
      {
        key: "item_delivered_bg_image",
        columnName: "Item Delivered Background",
        width: 100,
        label: "Item Delivered Background",
        type: "file",
        form: true,
        disableSorting: true,
        visible: true,
        render: (_, values) =>
          values.item_delivered_bg_image && (
            <div
              style={{
                height: 64,
                width: 64,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${values.item_delivered_bg_image})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                }}
              ></div>
            </div>
          ),
      },
      {
        key: "call_to_action_img_url",
        columnName: "Call to action",
        width: 100,
        label: "Call to action",
        type: "file",
        form: true,
        disableSorting: true,
        visible: true,
        render: (_, values) =>
          values.call_to_action_img_url && (
            <div
              style={{
                height: 64,
                width: 64,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${values.call_to_action_img_url})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                }}
              ></div>
            </div>
          ),
      },
    ],
    [
      {
        key: "thank_you_screen_img_url",
        columnName: "Thank you screen",
        width: 100,
        label: "Thank you screen",
        type: "file",
        form: true,
        disableSorting: true,
        visible: true,
        render: (_, values) =>
          values.thank_you_screen_img_url && (
            <div
              style={{
                height: 64,
                width: 64,
              }}
            >
              <div
                style={{
                  backgroundImage: `url(${values.thank_you_screen_img_url})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                }}
              ></div>
            </div>
          ),
      },
    ],
    {
      key: "help_dialog_image",
      columnName: "Help Dialog image",
      width: 100,
      label: "Help Dialog image",
      type: "file",
      form: true,
      disableSorting: true,
      visible: true,
      render: (_, values) =>
        values.help_dialog_image && (
          <div
            style={{
              height: 64,
              width: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.help_dialog_image})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "credit_card_img_url",
      columnName: "Credit Card",
      width: 100,
      label: "Credit Card",
      type: "file",
      visible: true,
      render: (_, values) =>
        values.credit_card_img_url && (
          <div
            style={{
              height: 64,
              width: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.credit_card_img_url})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    // {
    //   key: "qr_code_img_url",
    //   columnName: "QR Code",
    //   width: 100,
    //   label: "QR Code",
    //   type: "file",
    //   visible: true,
    //   render: (_, values) =>
    //     values.qr_code_img_url && (
    //       <div
    //       style={{
    //         height: 64,
    //         width: 64,
    //       }}
    //     >
    //       <div
    //         style={{
    //           backgroundImage: `url(${values.qr_code_img_url})`,
    //           backgroundSize: "contain",
    //           backgroundRepeat: "no-repeat",
    //           backgroundPosition: "center",
    //           height: "100%",
    //           width: "100%",
    //         }}
    //       ></div>
    //     </div>
    //     ),
    // },
    {
      key: "promo_code_img_url",
      columnName: "Promotional Code",
      width: 100,
      label: "Promotional Code",
      type: "file",
      visible: true,
      render: (_, values) =>
        values.promo_code_img_url && (
          <div
            style={{
              height: 64,
              width: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.promo_code_img_url})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    [
      {
        key: "background_opacity",
        columnName: "Background opacity",
        label: "Background opacity",
        type: "text",
        visible: true,
        render: (value) => value || "---",
      },
      {
        key: "item_background_opacity",
        columnName: "Item background opacity",
        label: "Item background opacity",
        type: "text",
        visible: true,
        render: (value) => value || "---",
      },
      {
        key: "category_background_opacity",
        columnName: "Category background opacity",
        label: "Category background opacity",
        type: "text",
        visible: true,
        render: (value) => value || "---",
      },
      {
        key: "dispensing_background_opacity",
        columnName: "Dispensing background opacity",
        label: "Dispensing background opacity",
        type: "text",
        visible: true,
        render: (value) => value || "---",
      },
      {
        key: "item_delivered_background_opacity",
        columnName: "Item delivered background opacity",
        label: "Item delivered background opacity",
        type: "text",
        visible: true,
        render: (value) => value || "---",
      },
      {
        key: "devices",
        columnName: "Applied Devices",
        type: "text",
        visible: true,
        render: (value) =>
          Array.isArray(value)
            ? value?.map((x) => x.serial_number)?.join(", ") || "---"
            : "---",
        form: false,
        disableSorting: true,
      },

      {
        key: "layout",
        label: "Layout",
        columnName: "Layout",
        type: "autocomplete",
        show: true,
        required: true,
        freeSolo: false,
        options: categoryList.length
          ? [
              ...categoryList.map((x) => ({
                label: x.category_name,
                value: x.id,
              })),
            ]
          : [{ label: "", value: null }],
        required: true,
        visible: true,
        render: (value) => HandleLayoutName(value) || "---",
        disableSorting: true,
      },
    ],
    {
      key: "updated_at",
      columnName: "Updated At",
      type: "text",
      visible: true,
      render: (val) => (val ? dateFormatter(val, true) : "---"),
      form: false,
    },
  ];

  const layoutName = {
    default: "Layout 4x4",
    "Layout 1": "Layout 2x3",
    "Layout 2": "Layout 2x2",
    "Layout 3": "Layout 1x2",
    "Layout 12": "Layout 3x4",
    "Layout 9": "Layout 3x3",
  };
  const HandleLayoutName = (data) => {
    return layoutName[data] || "default";
  };

  const editFields = [
    {
      key: "enable_category_search",
      columnName: "Enable Category Search",
      label: "Enable Category Search",
      type: "text",
      visible: true,
      form: false,
    },
    ...(enable_product_dropdown
      ? [
          {
            key: "enable_language_dropdown",
            columnName: "Enable Language Dropdown",
            label: "Enable Language Dropdow",
            type: "text",
            visible: true,
            form: false,
          },
        ]
      : []),
    {
      key: "disable_call_to_action",
      columnName: "Disable Call To Action",
      label: "Disable Call To Action",
      type: "text",
      visible: true,
      form: false,
    },
    {
      key: "disable_thank_you_screen",
      columnName: "Skip Thank You Page",
      label: "Skip Thank You Page",
      type: "text",
      visible: true,
      form: false,
    },
    {
      key: "disable_price_info_cc",
      columnName: "Disable Price Info and Credit Card",
      label: "Disable Price Info and Credit Card",
      type: "text",
      visible: true,
      form: false,
    },
    {
      key: " show_accessibility",
      columnName: "Show accessibility",
      label: "Show accessibility",
      type: "text",
      visible: true,
      form: false,
    },
    {
      key: "help_option",
      columnName: "Help Option",
      label: "Help Option",
      type: "text",
      visible: true,
      form: false,
    },
    {
      key: "hide_product_info",
      columnName: "Product Info",
      label: "Product Info",
      type: "text",
      visible: true,
      form: false,
    },
    {
      key: "hide_category_dropdown",
      columnName: "Category Dropdown",
      label: "Category Dropdown",
      type: "text",
      visible: true,
      form: false,
    },
    {
      key: "id",
      columnName: "ID",
      label: "ID",
      type: "text",
      visible: true,
      form: false,
    },
    [
      {
        key: "name",
        columnName: "Theme Name",
        label: "Theme Name",
        type: "text",
        visible: true,
      },
    ],
    [
      {
        key: "primary_color",
        columnName: "Primary color",
        label: "Primary color",
        value: "#000000",
        type: "color",
        visible: true,
      },
      {
        key: "secondary_color",
        columnName: "Text Color",
        label: "Text Color",
        value: "#000000",
        type: "color",
        visible: true,
      },
      {
        key: "text_button_color",
        columnName: "Button Text Color",
        label: "Button Text Color",
        value: "#000000",
        type: "color",
        visible: true,
      },
    ],
    [
      {
        key: "item_background_opacity",
        columnName: "Item background opacity",
        label: "Item background opacity",
        type: "number",
        visible: true,
        max: 100,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => fieldValue >= 0 && fieldValue <= 100,
            message: "Write Number between 0 to 100",
          },
        ],
      },
      {
        key: "category_background_opacity",
        columnName: "Category Background opacity",
        label: "Category Background opacity",
        type: "number",
        visible: true,
        max: 100,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => fieldValue >= 0 && fieldValue <= 100,
            message: "Write Number between 0 to 100",
          },
        ],
      },
      {
        key: "background_opacity",
        columnName: "Background opacity",
        label: "Background opacity",
        type: "number",
        visible: true,
        max: 100,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => fieldValue >= 0 && fieldValue <= 100,
            message: "Write Number between 0 to 100",
          },
        ],
      },
      {
        key: "dispensing_background_opacity",
        columnName: "Dispensing opacity",
        label: "Dispensing opacity",
        type: "number",
        visible: true,
        max: 100,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => fieldValue >= 0 && fieldValue <= 100,
            message: "Write Number between 0 to 100",
          },
        ],
      },
      {
        key: "item_delivered_background_opacity",
        columnName: "Item delivered opacity",
        label: "Item delivered opacity",
        type: "number",
        visible: true,
        max: 100,
        validations: [
          {
            type: "custom",
            value: (fieldValue) => fieldValue >= 0 && fieldValue <= 100,
            message: "Write Number between 0 to 100",
          },
        ],
      },
    ],
    [
      {
        key: "layout",
        label: "Layout",
        columnName: "Layout",
        type: "autocomplete",
        show: true,
        defaultValue: "default",
        required: true,
        freeSolo: false,
        options: [
          { label: "Default", value: "default" },
          { label: "Layout 1", value: "Layout 1" },
          { label: "Layout 2", value: "Layout 2" },
          { label: "Layout 3", value: "Layout 3" },
          { label: "Layout 4", value: "Layout 4" },
        ],
        // options: categoryList.length
        //   ? [
        //       ...categoryList.map((x) => ({
        //         label: x.category_name,
        //         value: x.id,
        //       })),
        //     ]
        //   : [{ label: "Default", value: "default" }],
        visible: true,
      },
    ],
    [
      {
        key: "logo_url",
        columnName: "Logo",
        width: 100,
        label: "Logo",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.background_img && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.background_img}
              alt="Logo"
            />
          ),
      },
      {
        key: "background_img_url",
        columnName: "Background Image",
        width: 100,
        label: "Background Image",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.background_img && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.background_img}
              alt="Background Image"
            />
          ),
      },
      {
        key: "item_background_img_url",
        columnName: "Item Background",
        width: 100,
        label: "Item Background",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.item_background_img_url && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.item_background_img_url}
              alt="Item Background"
            />
          ),
      },
      {
        key: "category_bg_image",
        columnName: "Category Background",
        width: 100,
        label: "Category Background",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.category_bg_image && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.category_bg_image}
              alt="Category Background"
            />
          ),
      },
      {
        key: "dispensing_bg_image",
        columnName: "Dispensing Background",
        width: 100,
        label: "Dispensing Background",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.dispensing_bg_image && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.dispensing_bg_image}
              alt="Category Background"
            />
          ),
      },
      {
        key: "item_delivered_bg_image",
        columnName: "Item Delivered Background",
        width: 100,
        label: "Item Delivered Background",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.item_delivered_bg_image && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.item_delivered_bg_image}
              alt="Item Delivered Background"
            />
          ),
      },
      {
        key: "call_to_action_img_url",
        columnName: "Call To Action Image",
        width: 100,
        label: "Call To Action Image",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.call_to_action_img_url && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.call_to_action_img_url}
              alt="Call to Action Image"
            />
          ),
      },
      {
        key: "thank_you_screen_img_url",
        columnName: "Call To Action Image",
        width: 100,
        label: "Call To Action Image",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.thank_you_screen_img_url && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.thank_you_screen_img_url}
              alt="Call to Action Image"
            />
          ),
      },
    ],
    [
      {
        key: "credit_card_img_url",
        columnName: "Credit Card",
        width: 100,
        label: "Credit Card",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.credit_card_img_url && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.credit_card_img_url}
              alt="Credit Card"
            />
          ),
      },
      // {
      //   key: "qr_code_img_url",
      //   columnName: "QR Code",
      //   width: 100,
      //   label: "QR Code",
      //   type: "file",
      //   form: true,
      //   visible: true,
      //   render: (_, values) =>
      //     values.qr_code_img_url && (
      //       <img
      //         style={{ width: 84, height: 64 }}
      //         src={values.qr_code_img_url}
      //         alt="QR Code"
      //       />
      //     ),
      // },
      {
        key: "promo_code_img_url",
        columnName: "Promotional Code",
        width: 100,
        label: "Promotional Code",
        type: "file",
        form: true,
        visible: true,
        render: (_, values) =>
          values.promo_code_img_url && (
            <img
              style={{ width: 84, height: 64 }}
              src={values.promo_code_img_url}
              alt="Promotional Code"
            />
          ),
      },
    ],
    {
      key: "help_dialog_image",
      columnName: "Help Dialog image",
      width: 100,
      label: "Help Dialog image",
      type: "file",
      form: true,
      visible: true,
      render: (_, values) =>
        values.help_dialog_image && (
          <img
            style={{ width: 84, height: 64 }}
            src={values.help_dialog_image}
            alt="Help Dialog image"
          />
        ),
    },
  ];

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results);
    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getDeviceType = async (order, max, pageNumber = null) => {
    const realPage = pageNumber ? 1 : page + 1;

    const params = {
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      page: realPage,
    };
    setup();
    const { data } = await window.axiosIns("/themes", { params });
    handleRes(data);
  };

  const getDevices = async (order, max) => {
    const params = {
      all: true,
      ordering: "vm_name",
    };
    setup();
    const { data } = await window.axiosIns("/device", { params });
    setDevicesList(data?.data?.results);
  };

  const getAdDevices = async (adSelect) => {
    const ad_id = adSelect && adSelect[0]?.id;
    if (ad_id) {
      try {
        const { data } = await window.axiosIns(`themes/${ad_id}`);
        setAdDeviceList(
          _.map(data?.devices, (x) => ({
            // oro_id:x.oro_id,
            value: x.id,
            label: `${x.serial_number} || ${x.vm_name || ""}`,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getDeviceType();
    getDevices();
    getAdDevices();
  }, []);

  const isFilesValid = (files) => {
    let isValid = true;

    files.forEach((file) => {
      if (typeof file === "string") {
        if (
          /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/.test(
            file
          )
        ) {
          return;
        }
      } else if (file?.name && !/\.(gif|jpg|jpeg|png)$/i.test(file?.name)) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleAdd = ({
    name,
    primary_color,
    secondary_color,
    text_button_color,
    item_background_opacity,
    background_opacity,
    layout,
    logo_url,
    background_img_url,
    credit_card_img_url,
    // qr_code_img_url,
    promo_code_img_url,
    item_background_img_url,
    call_to_action_img_url,
    disable_thank_you_screen,
    disable_price_info_cc,
    enable_category_search,
    enable_language_dropdown,
    disable_call_to_action,
    is_category_screen_default,
    show_accessibility,
    show_product_info,
    show_category_dropdown,
    thank_you_screen_img_url,
    help_dialog_image,
    help_option,
    category_background_opacity,
    category_bg_image,
    dispensing_bg_image,
    item_delivered_bg_image,
    dispensing_background_opacity,
    item_delivered_background_opacity,
  }) => {
    // const isValid = isFilesValid([background_img_url, credit_card_img_url, qr_code_img_url, promo_code_img_url, logo_url, item_background_img_url, call_to_action_img_url, thank_you_screen_img_url, help_dialog_image]);
    const isValid = isFilesValid([
      background_img_url,
      credit_card_img_url,
      promo_code_img_url,
      logo_url,
      item_background_img_url,
      call_to_action_img_url,
      thank_you_screen_img_url,
      help_dialog_image,
      category_bg_image,
      dispensing_bg_image,
      item_delivered_bg_image,
    ]);

    if (!isValid) {
      enqueueSnackbar(`Only png, jpg, jpeg, gif files are supported.`);
      return false;
    }

    const fd = new FormData();
    logo_url && fd.append("logo_url", logo_url);
    background_img_url && fd.append("background_img_url", background_img_url);
    credit_card_img_url &&
      fd.append("credit_card_img_url", credit_card_img_url);
    // qr_code_img_url && fd.append("qr_code_img_url", qr_code_img_url);
    promo_code_img_url && fd.append("promo_code_img_url", promo_code_img_url);
    item_background_img_url &&
      fd.append("item_background_img_url", item_background_img_url);
    call_to_action_img_url &&
      fd.append("call_to_action_img_url", call_to_action_img_url);
    thank_you_screen_img_url &&
      fd.append("thank_you_screen_img_url", thank_you_screen_img_url);
    help_dialog_image && fd.append("help_dialog_image", help_dialog_image);
    category_bg_image && fd.append("category_bg_image", category_bg_image);
    dispensing_bg_image &&
      fd.append("dispensing_bg_image", dispensing_bg_image);
    item_delivered_bg_image &&
      fd.append("item_delivered_bg_image", item_delivered_bg_image);

    fd.append("operator_id", current_user?.company?.company_id || "");
    fd.append("name", name);
    fd.append("primary_color", primary_color);
    fd.append("secondary_color", secondary_color);
    fd.append("text_button_color", text_button_color);
    fd.append("item_background_opacity", item_background_opacity ?? "");
    fd.append("category_background_opacity", category_background_opacity ?? "");
    fd.append(
      "dispensing_background_opacity",
      dispensing_background_opacity ?? ""
    );
    fd.append(
      "item_delivered_background_opacity",
      item_delivered_background_opacity ?? ""
    );

    fd.append("background_opacity", background_opacity ?? "");
    fd.append("layout", layout ?? "default");
    fd.append(
      "disable_thank_you_screen",
      disable_thank_you_screen === undefined ? false : disable_thank_you_screen
    );
    fd.append(
      "disable_price_info_cc",
      disable_price_info_cc === undefined ? false : disable_price_info_cc
    );
    fd.append(
      "enable_category_search",
      enable_category_search === undefined ? false : enable_category_search
    );
    fd.append(
      "is_category_screen_default",
      is_category_screen_default === undefined
        ? false
        : is_category_screen_default
    );
    fd.append(
      "enable_language_dropdown",
      enable_language_dropdown === undefined ? false : enable_language_dropdown
    );
    fd.append(
      "disable_call_to_action",
      disable_call_to_action === undefined ? false : disable_call_to_action
    );
    fd.append(
      "show_product_info",
      show_product_info === undefined ? true : show_product_info
    );
    fd.append(
      "show_category_dropdown",
      show_category_dropdown === undefined ? true : show_category_dropdown
    );
    fd.append("help_option ", help_option === undefined ? true : help_option);
    fd.append(
      "show_accessibility",
      show_accessibility === undefined ? true : show_accessibility
    );
    setCrudLoader(true);
    window.axiosIns
      .post(`/themes`, fd)
      .then(() => {
        setCrudLoader(false);
        setAddModal(false);
        setEditModal(false);
        enqueueSnackbar("Theme Added successfully.");
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add product. Try again."
          );
        }
        setCrudLoader(false);
      })
      .then(() => {
        getDeviceType();
        setModulesSelected([]);
      })
      .finally(() => setIsLoading(false));
  };

  const handleEdit = ({
    name,
    primary_color,
    secondary_color,
    text_button_color,
    item_background_opacity,
    background_opacity,
    layout,
    logo_url,
    background_img_url,
    item_background_img_url,
    credit_card_img_url,
    // qr_code_img_url,
    promo_code_img_url,
    call_to_action_img_url,
    disable_thank_you_screen,
    disable_price_info_cc,
    is_category_screen_default,
    enable_category_search,
    enable_language_dropdown,
    disable_call_to_action,
    show_accessibility,
    show_category_dropdown,
    show_product_info,
    thank_you_screen_img_url,
    help_dialog_image,
    help_option,
    category_background_opacity,
    category_bg_image,
    dispensing_bg_image,
    item_delivered_bg_image,
    dispensing_background_opacity,
    item_delivered_background_opacity,
  }) => {
    // const isValid = isFilesValid([background_img_url, credit_card_img_url, qr_code_img_url, promo_code_img_url, logo_url, item_background_img_url, call_to_action_img_url, thank_you_screen_img_url, help_dialog_image]);
    const isValid = isFilesValid([
      background_img_url,
      credit_card_img_url,
      promo_code_img_url,
      logo_url,
      item_background_img_url,
      call_to_action_img_url,
      thank_you_screen_img_url,
      help_dialog_image,
    ]);
    if (!isValid) {
      enqueueSnackbar(`Only png, jpg, jpeg, gif files are supported.`);
      return false;
    }

    const fd = new FormData();
    fd.append("operator_id", current_user?.company?.company_id || "");
    // logo_url && logo_url.type && fd.append("logo_url", logo_url);
    // For promo_code_img_url
    if (promo_code_img_url === null) {
      fd.append("promo_code_img_url", "");
    } else if (promo_code_img_url && promo_code_img_url.type) {
      fd.append("promo_code_img_url", promo_code_img_url);
    }

    // For logo_url
    if (logo_url === null) {
      fd.append("logo_url", "");
    } else if (logo_url && logo_url.type) {
      fd.append("logo_url", logo_url);
    }

    if (background_img_url === null) {
      fd.append("background_img_url", "");
    } else if (background_img_url && background_img_url.type) {
      fd.append("background_img_url", background_img_url);
    }
    // For item_background_img_url
    if (item_background_img_url === null) {
      fd.append("item_background_img_url", "");
    } else if (item_background_img_url && item_background_img_url.type) {
      fd.append("item_background_img_url", item_background_img_url);
    }

    // For call_to_action_img_url
    if (call_to_action_img_url === null) {
      fd.append("call_to_action_img_url", "");
    } else if (call_to_action_img_url && call_to_action_img_url.type) {
      fd.append("call_to_action_img_url", call_to_action_img_url);
    }

    // For thank_you_screen_img_url
    if (thank_you_screen_img_url === null) {
      fd.append("thank_you_screen_img_url", "");
    } else if (thank_you_screen_img_url && thank_you_screen_img_url.type) {
      fd.append("thank_you_screen_img_url", thank_you_screen_img_url);
    }

    // For help_dialog_image
    if (help_dialog_image === null) {
      fd.append("help_dialog_image", "");
    } else if (help_dialog_image && help_dialog_image.type) {
      fd.append("help_dialog_image", help_dialog_image);
    }

    // For credit_card_img_url
    if (credit_card_img_url === null) {
      fd.append("credit_card_img_url", "");
    } else if (credit_card_img_url && credit_card_img_url.type) {
      fd.append("credit_card_img_url", credit_card_img_url);
    }

    if (category_bg_image === null) {
      fd.append("category_bg_image", "");
    } else if (category_bg_image && category_bg_image.type) {
      fd.append("category_bg_image", category_bg_image);
    }
    if (dispensing_bg_image === null) {
      fd.append("dispensing_bg_image", "");
    } else if (dispensing_bg_image && dispensing_bg_image.type) {
      fd.append("dispensing_bg_image", dispensing_bg_image);
    }
    if (item_delivered_bg_image === null) {
      fd.append("item_delivered_bg_image", "");
    } else if (item_delivered_bg_image && item_delivered_bg_image.type) {
      fd.append("item_delivered_bg_image", item_delivered_bg_image);
    }

    // item_background_img_url &&item_background_img_url.type &&
    //   fd.append("item_background_img_url", item_background_img_url);
    // call_to_action_img_url && call_to_action_img_url.type &&
    //   fd.append("call_to_action_img_url", call_to_action_img_url);
    // thank_you_screen_img_url && thank_you_screen_img_url.type &&
    //   fd.append("thank_you_screen_img_url", thank_you_screen_img_url);
    // help_dialog_image && help_dialog_image.type &&
    //   fd.append("help_dialog_image", help_dialog_image);
    // credit_card_img_url && credit_card_img_url.type &&
    //   fd.append("credit_card_img_url", credit_card_img_url);
    // qr_code_img_url && qr_code_img_url.type &&
    //   fd.append("qr_code_img_url", qr_code_img_url);
    // promo_code_img_url && promo_code_img_url.type &&
    //   fd.append("promo_code_img_url", promo_code_img_url);

    fd.append("name", name);
    fd.append("primary_color", primary_color);
    fd.append("secondary_color", secondary_color);
    fd.append("text_button_color", text_button_color);
    fd.append("item_background_opacity", item_background_opacity ?? "");
    fd.append("category_background_opacity", category_background_opacity ?? "");
    fd.append("background_opacity", background_opacity ?? "");
    fd.append(
      "dispensing_background_opacity",
      dispensing_background_opacity ?? ""
    );
    fd.append(
      "item_delivered_background_opacity",
      item_delivered_background_opacity ?? ""
    );
    fd.append("layout", layout);
    fd.append(
      "disable_thank_you_screen",
      disable_thank_you_screen === undefined ? false : disable_thank_you_screen
    );
    fd.append(
      "disable_price_info_cc",
      disable_price_info_cc === undefined ? false : disable_price_info_cc
    );
    fd.append(
      "enable_category_search",
      enable_category_search === undefined ? false : enable_category_search
    );
    fd.append(
      "enable_language_dropdown",
      enable_language_dropdown === undefined ? false : enable_language_dropdown
    );
    fd.append(
      "disable_call_to_action",
      disable_call_to_action === undefined ? false : disable_call_to_action
    );
    fd.append(
      " is_category_screen_default",
      is_category_screen_default === undefined
        ? false
        : is_category_screen_default
    );
    fd.append("show_product_info", show_product_info);
    fd.append("show_category_dropdown", show_category_dropdown);
    fd.append("show_accessibility", show_accessibility);
    fd.append("help_option", help_option);

    const moduleId = modulesSelected[0].id;
    setCrudLoader(true);
    window.axiosIns
      .put(`/themes/${moduleId}`, fd)
      .then(() => {
        setCrudLoader(false);
        setEditModal(false);
        enqueueSnackbar("Theme applied successfully.");
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not apply theme. Try again."
          );
        }
        setCrudLoader(false);
        setEditModal(false);
      })
      .then(() => {
        setDeviceList([]);
        setModulesSelected([]);
        getDeviceType();
      })
      .finally(() => setIsLoading(false));
  };

  const createTemplate = (values) => {
    const updatedData = objectDifference(values, modulesSelected[0]);

    const fd = new FormData();
    fd.append("operator_id", current_user?.company?.company_id || "");
    const fileTypeArray = [];
    Object.keys(updatedData).forEach(function (key) {
      if (typeof updatedData[key] === "object") {
        fileTypeArray.push(updatedData[key]);
      }
      fd.append(key, updatedData[key]);
    });

    const isValid = isFilesValid(fileTypeArray);
    if (!isValid) {
      enqueueSnackbar(`Only png, jpg, jpeg, gif files are supported.`);
      return false;
    }

    const moduleId = modulesSelected[0].id;
    setCrudLoader(true);
    window.axiosIns
      .post(`/themes/${moduleId}/create_copy`, fd)
      .then(() => {
        setCrudLoader(false);
        setEditModal(false);
        enqueueSnackbar("Template Created successfully.");
        setModulesSelected([]);
        getDeviceType();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not create template. Try again."
          );
        }
        setCrudLoader(false);
      })
      .finally(() => setIsLoading(false));
  };

  const handleAllocate = (data) => {
    const themeId = (modulesSelected[0] || {}).id;
    setCrudLoader(true);

    window.axiosIns
      .post(`/apply_theme`, { theme_id: themeId, devices: data.device_id })

      .then(() => {
        setCrudLoader(false);
        setAllocateModal(false);
        enqueueSnackbar("Theme Applied successfully.");
      })
      .catch((err) => {
        setCrudLoader(false);
        setAllocateModal(false);
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not applied theme. Try again."
          );
        }
      })
      .then(() => {
        setModulesSelected([]);
        getDeviceType();
      });
  };

  const handleUnApplyVm = async (data) => {
    const adDevicesList = data?.devices;

    try {
      const ad_id = modulesSelected[0].id;

      setCrudLoader(true);
      await window.axiosIns.put(`themes/unapply_theme`, {
        devices: adDevicesList,
      });

      enqueueSnackbar("Theme successfully unassigned to vending machine");
      setCrudLoader(false);
      setUnAssignModal(false);
      getAdDevices();
      getDeviceType();
      setModulesSelected([]);
    } catch (err) {
      setCrudLoader(false);
      handleServerErrors(
        err,
        enqueueSnackbar,
        "Could not Unapply theme. Try again."
      );
    }
  };

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/themes", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = async (url) => {
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  const handleSearch = (value) => {
    setQuery(value);
    if (value !== "") {
      let searchFilter = handleMultiFilterSearch(
        filterLabels,
        filterable,
        value
      );
      setup();
      setPage(0);

      window
        .axiosIns("/themes", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            ordering,
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    } else {
      getDeviceType();
    }
  };

  const handleDelete = () => {
    modulesSelected.forEach((val, index) => {
      window.axiosIns
        .delete(`/themes/${val.id}`)
        .then(() => {
          setDeleteModal(false);
          if (modulesSelected.length === index + 1) {
            if (
              deviceTypeList.length - modulesSelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getDeviceType();
            }
            enqueueSnackbar("Theme deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete theme. Try again."
            );
          }
        });
    });
  };

  const handleTypeSelection = (field, value) => {
    if (field.key === "device_type") {
      setTypeSelection(value);
    }
  };
  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        // title="Theme Manager"
        description="
        All Theme are listed here. You can create a new theme."
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => setAddModal(true)}
            disabled={current_user.type === "SU"}
          />
          <AllocateButton
            disabled={
              current_user.type === "SU" || modulesSelected.length !== 1
            }
            className="mr-3"
            label="Apply"
            onClick={() => {
              setAllocateModal(true);
              // setTypeSelection((modulesSelected[0] || {}).device_type_id);
            }}
          />
          <UnAllocateButton
            disabled={
              current_user.type === "SU" || modulesSelected.length !== 1
            }
            className="mr-3"
            label="Unapply"
            onClick={() => {
              setUnAssignModal(true);
            }}
          />
          <EditButton
            disabled={
              modulesSelected.length !== 1 || current_user.type === "SU"
            }
            className="mr-3"
            label="Edit"
            onClick={() => setEditModal(true)}
          />
          <DeleteButton
            disabled={
              modulesSelected.length === 0 || current_user.type === "SU"
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
        </div>
        <div className="d-flex">
          <SearchBox
            placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={_flatten(fields)}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(ordering);
          }}
          // onChangePage={(page) => console.log(page)}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, true);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) => {
            setModulesSelected(modulesSelected);
            getAdDevices(modulesSelected);
          }}
        />
        <CrudDialog
          title="Add Theme"
          okText="Add Theme"
          fields={editFields}
          description="Please fill in the details below."
          crudLoader={crudLoader}
          onSubmit={(values, hasErrors) => {
            handleAdd(values);
          }}
          open={addModal}
          onClose={() => setAddModal(false)}
          loading={isLoading}
          isLanguageDropDown={enable_product_dropdown}
        />
        <CrudDialog1
          title="Apply Theme"
          okText="Apply"
          description="Please fill the details below."
          crudLoader={crudLoader}
          fields={allocateFields}
          values={modulesSelected[0]}
          onSubmit={(values, hasErrors) => {
            handleAllocate(values);
          }}
          // extraButtonText="Allocate"
          hide={hideAllocateModal}
          onExtraButton={() => {
            // fetchDevicePasscode()
            // setPasscodeModal(true);
            setHideAllocateModal(true);
          }}
          open={allocateModal}
          onClose={() => setAllocateModal(false)}
        />

        <CrudDialog1
          title="Unapply Theme"
          okText="Unapply"
          description="Please fill the details below."
          crudLoader={crudLoader}
          fields={UnassignDialogFields}
          values={modulesSelected[0]}
          onSubmit={(values) => {
            handleUnApplyVm(values);
          }}
          open={UnassignModal}
          onClose={() => setUnAssignModal(false)}
        />

        <CrudDialog
          title="Edit Theme"
          okText="Apply to Machines"
          cancelText="Discard"
          description="Please edit the details below."
          crudLoader={crudLoader}
          fields={editFields}
          values={modulesSelected[0]}
          onSubmit={(values) => {
            handleEdit(values);
          }}
          extraSubmitButtonText="Create New Template"
          onExtraSubmitButton={(values) => {
            values.name = values.name + "_copy";
            createTemplate(values);
          }}
          open={editModal}
          onClose={() => setEditModal(false)}
          loading={isLoading}
          isLanguageDropDown={enable_product_dropdown}
        />
        <CrudDialog1
          title="Delete Theme"
          description="Are you sure you want to delete the Theme?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(ThemeApp);
