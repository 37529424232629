import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import { Loader } from "components/Common";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import _ from "lodash";
import "./style.css";

function TablePaginationActions(props, compProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0, "first");
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1, "back");
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1, "next");
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(
      event,
      Math.max(0, Math.ceil(count / rowsPerPage) - 1),
      "last"
    );
  };

  return (
    <div>
      <div
        className={classes.root}
        style={{
          paddingTop:
            compProps.selectedRecords &&
            compProps.selectedRecords.length &&
            !isEmpty(compProps.selectedRecords[0])
              ? "20px"
              : "0px",
        }}
      >
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
      {compProps.selectedRecords &&
      compProps.selectedRecords.length &&
      !isEmpty(compProps.selectedRecords[0]) ? (
        <div style={{ display: "inline-block" }}>
          {" "}
          {compProps.selectedRecords.length} Record
          {compProps.selectedRecords.length === 1 ? "" : "s"} Selected{" "}
        </div>
      ) : null}
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    fields,
    collapsible,
    defaultPadding,
    isDashboardSales,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      style={{ position: "sticky", top: 0, backgroundColor: "#ECF0F1" }}
    >
      <TableRow className={classes.headRow}>
        {!isDashboardSales && <StyledTableCell colSpan={1} />}
        {fields
          .filter((x) => x.visible)
          .map((headCell) => (
            <StyledTableCell
              key={headCell.key}
              align={headCell.align ? headCell.align : "left"}
              sortDirection={orderBy === headCell.key ? order : false}
            >
              {headCell?.disableColumnSort ? (
                <TableRow className={classes.headCell}>
                  {headCell.columnName}
                </TableRow>
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.key}
                  direction={orderBy === headCell.key ? order : "asc"}
                  onClick={createSortHandler(headCell.key)}
                  className={classes.headCell}
                  style={{ paddingRight: headCell?.paddingRight }}
                  disabled={headCell?.disableSorting}
                >
                  {headCell.columnName}
                  {orderBy === headCell.key ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              )}
            </StyledTableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  fields: PropTypes.array.isRequired,
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    minHeight: 50,
    "&:nth-of-type(even)": {
      backgroundColor: "#fafafc",
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 12,
    lineHeight: 1.4,
  },
  body: {
    fontSize: 12,
    color: "#686770",
  },
  root: {
    borderBottom: "none",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    border: "1px solid #dbdbdb",
    margin: "50!important",
    overflow: "auto",
    position: "relative",
  },
  headRow: {
    background: "#f4f4fb",
  },
  root: {
    width: "100%",
    display: "inline",
  },
  paper: {
    position: "relative",
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableHead: {
    backgroundColor: "#ECF0F1",
  },
  headCell: {
    fontWeight: "bold",
    fontSize: "12px",
    width: "100%",
    textAlign: "center",
  },
  pagination: {
    fontSize: "10px",
  },
  tableRowSelected: {
    "&$selected, &$selected:hover": {
      backgroundColor: "transparent",
    },
  },
  tableCell: {
    fontSize: "12px",
    marginLeft: "100px",
  },
  selected: {},
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#686770",
    },
    "& .Mui-selected": {
      color: "#fff",
      border: "none",
    },
  },
  pagination: {
    marginTop: 15,
    marginBottom: 15,
  },
}));

const Row = ({
  isItemSelected,
  record,
  collapsible,
  sensorTable,
  handleClick,
  labelId,
  fields,
  collapsibleHeader,
  collapsibleFields,
  collapsibleFieldKey,
  collapsibleHeader2,
  collapsibleFields2,
  collapsibleFieldKey2,
  collapsibleFieldKey3,
  collapsibleHeader3,
  collapsibleFields3,
  index,
  defaultPadding,
  isDashboardSales,
}) => {
  const classes = useStyles();

  const [collapseOpen, setCollapseOpen] = useState(false);
  const [collapseSecond, setCollapseSecond] = useState(true);
  const [collapseThird, setCollapseThird] = useState(false);
  const [collapseFourth, setCollapseFourth] = useState(false);

  return (
    <>
      <StyledTableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={record.name}
        classes={{ selected: classes.selected }}
        className={classes.tableRowSelected}
        selected={isItemSelected}
        style={{ height: 53 }}
      >
        {!isDashboardSales && (
          <>
            {collapsible ? (
              <StyledTableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setCollapseOpen((prevState) => !prevState)}
                >
                  {collapseOpen ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </StyledTableCell>
            ) : (
              <StyledTableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  style={{ visibility: sensorTable && "hidden" }}
                  onClick={(event) => handleClick(event, record)}
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </StyledTableCell>
            )}
          </>
        )}
        {fields
          .filter((x) => x.visible)
          .map((field) => (
            <StyledTableCell
              className={[
                classes.tableCell,
                defaultPadding && classes.defaultPadding,
              ]}
              align={field.align ? field.align : "left"}
            >
              <div style={{ paddingRight: field?.paddingRight }}>
                {field.render && typeof field.render === "function"
                  ? field.render(record[field.key], record, index)
                  : record[field.key]}
              </div>
            </StyledTableCell>
          ))}
      </StyledTableRow>
      {collapsible && collapseOpen && (
        <TableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={fields.length}
          >
            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
              <Box margin={1} style={{ marginLeft: "40px" }}>
                <Typography variant="h6" gutterBottom component="div">
                  {collapsibleHeader}
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => setCollapseSecond((prevState) => !prevState)}
                  >
                    {collapseSecond ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </Typography>
                <Collapse in={collapseSecond} timeout="auto" unmountOnExit>
                  <Table
                    size="small"
                    aria-label="purchases"
                    style={{ marginBottom: 16 }}
                  >
                    <TableHead>
                      <TableRow>
                        {_.map(collapsibleFields, (x) => (
                          <StyledTableCell key={x.key}>
                            <b>{x.columnName}</b>
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_.map(
                        record?.[collapsibleFieldKey],
                        (collapsibleRow, i) => (
                          <TableRow key={i}>
                            {_.map(collapsibleFields, (x) => (
                              <StyledTableCell key={x.key}>
                                {x.render && typeof x.render === "function"
                                  ? x.render(collapsibleRow)
                                  : collapsibleRow[x.key]}
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </Collapse>
              </Box>
              {collapsibleFieldKey2 && (
                <Box margin={1} style={{ marginLeft: "40px" }}>
                  <Typography variant="h6" gutterBottom component="div">
                    {collapsibleHeader2}
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      style={{ marginLeft: 10 }}
                      onClick={() =>
                        setCollapseThird((prevState) => !prevState)
                      }
                    >
                      {collapseThird ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </Typography>
                  <Collapse in={collapseThird} timeout="auto" unmountOnExit>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          {_.map(collapsibleFields2, (x) => (
                            <StyledTableCell key={x.key}>
                              <b>{x.columnName}</b>
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {_.map(
                          record?.[collapsibleFieldKey2],
                          (collapsibleRow, i) => (
                            <TableRow key={i}>
                              {_.map(collapsibleFields2, (x) => (
                                <StyledTableCell key={x.key}>
                                  {x.render && typeof x.render === "function"
                                    ? x.render(collapsibleRow)
                                    : collapsibleRow[x.key]}
                                </StyledTableCell>
                              ))}
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </Collapse>
                </Box>
              )}
              <Box margin={1} style={{ marginLeft: "40px" }}>
                <Typography variant="h6" gutterBottom component="div">
                  {collapsibleHeader3}
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => setCollapseFourth((prevState) => !prevState)}
                  >
                    {collapseFourth ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </Typography>
                <Collapse in={collapseFourth} timeout="auto" unmountOnExit>
                  <Table
                    size="small"
                    aria-label="purchases"
                    style={{ marginBottom: 16 }}
                  >
                    <TableHead>
                      <TableRow>
                        {collapsibleFields3
                          .filter((x) => x.visible)
                          .map((x) => (
                            <StyledTableCell key={x.key}>
                              <b>{x.columnName}</b>
                            </StyledTableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {collapsibleFields3
                          .filter((x) => x.visible)
                          .map((x) => (
                            <StyledTableCell key={x.key}>
                              {x.render && typeof x.render === "function"
                                ? x.render(record?.[collapsibleFieldKey3])
                                : record?.[collapsibleFieldKey3][x.key]}
                            </StyledTableCell>
                          ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </Collapse>
              </Box>
            </Collapse>
          </StyledTableCell>
        </TableRow>
      )}
    </>
  );
};

const TableGenerator = ({
  fields = [],
  data = [],
  handleSortChange = () => {},
  currentPage = 0,
  initialSort = "",
  backendPagination = false,
  dataCount,
  searchQuery = "",
  onPageChange = () => {},
  onChangeSelected = () => {},
  onRowPerPageChange = () => {},
  rowOnePage = 10,
  radio = false,
  size = "medium",
  sensorTable = false,
  collapsible = false,
  collapsibleFieldKey,
  collapsibleFields,
  collapsibleHeader,
  collapsibleFieldKey2,
  collapsibleFields2,
  collapsibleHeader2,
  collapsibleFieldKey3,
  collapsibleHeader3,
  collapsibleFields3,
  isDashboardSales = false,
  sortOrder = "desc",
  ...props
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState(sortOrder);
  const [orderBy, setOrderBy] = useState(initialSort);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowOnePage);
  const [collapseOpen, setCollapseOpen] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    handleSortChange(`${isAsc ? "" : "-"}${property}`);
  };

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const handleClick = (event, name) => {
    const selectedIndex = props.selectedRecords.findIndex(
      (val) => val.id === name.id
    );
    let newSelected = [];

    if (radio) {
      if (selectedIndex) {
        newSelected = [name];
      } else {
        newSelected = [];
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(props.selectedRecords, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(props.selectedRecords.slice(1));
      } else if (selectedIndex === props.selectedRecords.length - 1) {
        newSelected = newSelected.concat(props.selectedRecords.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          props.selectedRecords.slice(0, selectedIndex),
          props.selectedRecords.slice(selectedIndex + 1)
        );
      }
    }
    onChangeSelected(newSelected);
  };

  const handleChangePage = (event, newPage, towards) => {
    setPage(newPage);
    onPageChange(newPage, towards);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onRowPerPageChange(event.target.value);
  };

  const handleSelectAll = (selected) => {
    if (selected) {
      onChangeSelected(data);
    } else {
      onChangeSelected([]);
    }
  };

  const isSelected = (record) =>
    props.selectedRecords.findIndex((x) => x.id === record.id) !== -1;

  return (
    <Paper
      elevation={0}
      className={`${classes.tableContainer} ${
        props.stickyTable ? "sticky-table" : ""
      }`}
    >
      <Loader visible={props.loader} />
      <Table
        className={!isDashboardSales ?? classes.table}
        aria-label="customized table"
        style={{ height: props.tableHeight ?? props.tableHeight }}
      >
        <EnhancedTableHead
          classes={classes}
          numSelected={props.selectedRecords.length}
          order={order}
          collapsible={collapsible}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
          fields={fields}
          data={data}
          showSelectAll={props.showSelectAll}
          selectedRecords={props.selectedRecords}
          onSelectAll={handleSelectAll}
          isDashboardSales={isDashboardSales}
        />
        <TableBody>
          {data
            .slice(
              backendPagination ? 0 : page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
            .map((record, index) => {
              const isItemSelected = isSelected(record);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <Row
                  labelId={labelId}
                  collapsibleFields={collapsibleFields}
                  fields={fields}
                  sensorTable={sensorTable}
                  handleClick={handleClick}
                  collapsibleFieldKey={collapsibleFieldKey}
                  index={index}
                  collapsibleHeader={collapsibleHeader}
                  isItemSelected={isItemSelected}
                  record={record}
                  collapsible={collapsible}
                  collapsibleHeader2={collapsibleHeader2}
                  collapsibleFields2={collapsibleFields2}
                  collapsibleFieldKey2={collapsibleFieldKey2}
                  collapsibleHeader3={collapsibleHeader3}
                  collapsibleFields3={collapsibleFields3}
                  collapsibleFieldKey3={collapsibleFieldKey3}
                  isDashboardSales={isDashboardSales}
                />
              );
            })}
          {!props.loader && data.length === 0 && (
            <StyledTableCell
              className="d-flex align-items-center justify-content-center"
              style={{
                position: "absolute",
                height: "20%",
                width: "100%",
              }}
            >
              <strong> No records found</strong>
            </StyledTableCell>
          )}
        </TableBody>
        <TableFooter className={`${props.stickyTable ? "sticky-footer" : ""}`}>
          <StyledTableRow
            style={{
              border: "none",
              borderTop: "0.5px solid #dbdbdb",
              width: "100%",
            }}
          >
            <TablePagination
              style={{ border: "none" }}
              rowsPerPageOptions={
                props?.rowOptions || [3, 5, 7, 8, 10, 25, 50, 100]
              }
              colSpan={fields.length}
              count={dataCount !== undefined ? dataCount : data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { "aria-label": "rows per page" },
              }}
              onPageChange={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={(e) => TablePaginationActions(e, props)}
            />
          </StyledTableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
};

TableGenerator.defaultProps = {
  selectedRecords: [],
  showSelectAll: true,
  handleSortChange: () => {},
};

export default withStyles({}, { withTheme: true })(TableGenerator);
