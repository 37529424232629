import React, { useState, useEffect, useCallback } from "react";
import CustomInputDatePicker from "../../../../../Common/CustomInputDatePicker";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { TableChart, Assessment } from "@material-ui/icons";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import TableGenerator from "../common/TableGenerator";
import moment from "moment";
import _ from "lodash";
import { useSelector } from "react-redux";
import { domainLevelConfig } from "../../../../../../ui-config";
import AsyncAutoComplete from "../common/AsyncAutoComplete";
import {
  convertUtcToLocal,
  dateFormatter,
  getFilterEndDate,
  getFilterStartDate,
  currencyFormatter,
} from "utils/helpers";
import MegaLineChart from "./MegaLinechart";
import { useSnackbar } from "notistack";
import { getTimeZoneDifference } from "utils/helpers";
import { ExportButton } from "components/Common/Buttons";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  graphContainer: {
    height: 400,
    position: "relative",
    marginTop: "20px",
    padding: 0,
  },
  selectHeader: {
    // marginBottom: "1rem",
    padding: "2rem 1rem 0 1rem",
  },
  wrapper: {
    padding: 20,
    backgroundColor: "white",
    borderRadius: "10px",
  },
  noDataMessage: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  toggleWrapper: {
    display: "inline-flex",
    justifyContent: "start",
  },
  chartNoData: {
    background: "#ededed",
    height: "400px",
    borderRadius: "0.25rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "40px",
  },
}));

const Salesfields = [
  {
    key: "date",
    visible: true,
    columnName: "Date",
    render: (value) => dateFormatter(convertUtcToLocal(value)) ?? "---",
  },
  {
    key: "product_name",
    visible: true,
    columnName: "Name",
    disableSorting: true
  },
  {
    key: "product_sku",
    visible: true,
    columnName: "SKU",
    render: (value) => (value && value.length > 0 ? value : "---"),
    disableSorting: true
  },
  {
    key: "quantity",
    visible: true,
    columnName: "Quantity",
    disableSorting: true
  },
  {
    key: "sales",
    visible: true,
    columnName: "Sales($)",
    disableSorting: true
  },
];

const Profitfields = [
  {
    key: "date",
    visible: true,
    columnName: "Date",
    render: (value) => dateFormatter(convertUtcToLocal(value)) ?? "---",
  },
  {
    key: "product_name",
    visible: true,
    columnName: "Name",
    disableSorting: true
  },
  {
    key: "product_sku",
    visible: true,
    columnName: "SKU",
    render: (value) => (value && value.length > 0 ? value : "---"),
    disableSorting: true
  },
  {
    key: "quantity",
    visible: true,
    columnName: "Quantity",
    disableSorting: true
  },
  {
    key: "profit",
    visible: true,
    columnName: "Profit($)",
    disableSorting: true
  },
];

const BarGraph = ({ data = [], loading, enableTaxes = false }) => {
  const host = useSelector((state) => state.userReducer.host);
  const theme = useSelector((state) => state.userReducer.theme);
  const user = useSelector((state) => state.userReducer?.current_user);
  const classes = useStyles();
  const hostConfig =
    domainLevelConfig[host] || domainLevelConfig["default"] || {};
  if (!data.length && !loading) {
    return (
      <div className={classes.chartNoData}>
        <div>No data for selected filters.</div>{" "}
      </div>
    );
  }
  return (
    <ResponsiveContainer width="102%" height="100%">
      <BarChart
        width={500}
        height={200}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" xAxisId={0}/>
        <XAxis dataKey="date" xAxisId={1} hide/>
        <XAxis dataKey="date" xAxisId={2} hide/>
        <YAxis
        // label={{ value: "Sales and Profit ($)", angle: -90, position: "insideLeft" }}
        />
        <Tooltip formatter={(value) => currencyFormatter(value)} />
        {/* color: props.theme.palette.primary.main
                                  ? props.theme.palette.primary.main
                                  : hostConfig.themeColor, */}
        <Legend verticalAlign="top" align="right" />
        <Bar
          name="Sales"
          dataKey="total_sales"
          xAxisId={2}
          fill="#024B7A"
        />

        <Bar
          name="Profit"
          dataKey="total_profit"
          xAxisId={1}
          fill="#44B7C2"
        />
        {enableTaxes && <Bar
          name="Tax"
          dataKey="total_tax"
          xAxisId={0}
          fill="#FFAE49"
        />}
      </BarChart>
    </ResponsiveContainer>
  );
};

function DashboardView() {
  const classes = useStyles();

  const [loadingYearly, setLoadingYearly] = useState(false);
  const [isYearlyExist, setIsYearlyExist] = useState(true);
  const [loadingDaily, setLoadingDaily] = useState(false);
  const [isDailyExist, setIsDailyExist] = useState(true);
  const [loadingRevenue, setLoadingRevenue] = useState(false);
  const [loadingProfit, setLoadingProfit] = useState(false);
  const [salesProfitData, setSalesProfitData] = useState({});
  const [salesProfitProductList, setSalesProfitProductList] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().subtract("days", 15).toDate()
  );
  const [endDate, setEndDate] = useState(new Date());

  /* Dropdown option list */
  const [operatorList, setOperatorList] = useState([
    { value: "all", label: "All" },
  ]);
  const [locationList, setLocationList] = useState([
    { value: "all", label: "All" },
  ]);
  const [areaList, setAreaList] = useState([{ value: "all", label: "All" }]);
  const [vmList, setVmList] = useState([{ value: "all", label: "All" }]);
  const [productList, setProductList] = useState([
    { value: "all", label: "All" },
  ]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  /* Dropdown selected values */
  const [selectedOperator, setSelectedOperator] = useState({
    value: "all",
    label: "All",
  });
  const [selectedLocation, setSelectedLocation] = useState({
    value: "all",
    label: "All",
  });
  const [selectedArea, setSelectedArea] = useState({
    value: "all",
    label: "All",
  });
  const [selectedVm, setSelectedVm] = useState([
    { value: "all", label: "All" },
  ]);
  const [totalData, setTotalData] = useState(null);
  const [allProducts, setAllProducts] = useState(null);
  /* Chart / Table data */
  const [dailyFinanceData, setDailyFinanceData] = useState([]);
  const [monthlyFinanceData, setMonthlyFinanceData] = useState([]);
  const [yearlyFinanceData, setYearlyFinanceData] = useState([]);
  const [endPreviousYear, setEndPreviousYear] = useState(0);
  const [salesRevenue, setSalesRevenue] = useState([]);
  const [salesProfit, setSalesProfit] = useState([]);

  /** Dropdown loading state */
  const [operatorListLoading, setOperatorListLoading] = useState(false);
  const [areaListLoading, setAreaListLoading] = useState(false);
  const [locationListLoading, setLocationListLoading] = useState(false);
  const [vmListLoading, setVmListLoading] = useState(false);

  /* Graph filter toggle */
  const [filterValue, setFilterValue] = useState("graph");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [more, setMore] = useState(false);
  const [ordering, setOrdering] = useState("date");
  const [enableTaxes, setEnableTaxes] = useState(false);
  const enableTaxesPerVM = useSelector((state) => state.companySettingsReducer.enableTaxesPerVM);

  const user = useSelector((state) => state.userReducer?.current_user);
  const { enqueueSnackbar } = useSnackbar();
  const fields = [
    {
      key: "date",
      visible: true,
      columnName: "Date",
    },
    {
      key: "total_sales",
      visible: true,
      columnName: "Total Sales",
    },
    {
      key: "quantity",
      visible: true,
      columnName: "Volume",
    },
    {
      key: "total_profit",
      visible: true,
      columnName: "Profit",
    },
    {
      key: "total_tax",
      visible: enableTaxes,
      columnName: "Tax",
    },
  ];
  
  const dailyfields = [
    {
      key: "date",
      visible: true,
      columnName: "Date",
      render: (value) => dateFormatter(convertUtcToLocal(value)) ?? "---",
    },
    {
      key: "total_sales",
      visible: true,
      columnName: "Total Sales",
    },
    {
      key: "quantity",
      visible: true,
      columnName: "Volume",
    },
    {
      key: "total_profit",
      visible: true,
      columnName: "Profit",
    },
    {
      key: "total_tax",
      visible: enableTaxes,
      columnName: "Tax",
    },
  ];
  const fetchLocations = async (areaList = [], area_id, selectedOperator) => {
    try {
      setLocationListLoading(true);
      if (area_id && area_id !== "all") {
        const list =
          _.find(areaList, (x) => x.value === area_id)?.locations || [];

        setLocationList([
          { label: "All", value: "all" },
          ..._.map(list, ({ location_id, location_name }) => ({
            label: location_name,
            value: location_id,
          })),
        ]);
      } else {
        if (selectedOperator && selectedOperator?.value !== "all") {
          let newLocation = [];
          const locationData = [
            ..._.map(areaList, ({ locations }) =>
              Array.prototype.push.apply(newLocation, locations)
            ),
          ];
          setLocationList([
            { label: "All", value: "all" },
            ..._.map(newLocation, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),
          ]);
        } else {
          const { data } = await window.axiosIns("locations/locations", {
            params: { all: true, state: "all", ordering: "location_name" },
          });
          setLocationList([
            { label: "All", value: "all" },
            ..._.map(data?.results, ({ location_id, location_name }) => ({
              label: location_name,
              value: location_id,
            })),
          ]);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLocationListLoading(false);
    }
  };

  const fetchOperators = async (location) => {
    try {
      setOperatorListLoading(true);
      const { data } = await window.axiosIns("company", {
        params: { all: true, location, ordering: "business_name" },
      });

      const list = data?.data?.results;

      const dropdownMap = [
        { value: "all", label: "All" },
        ..._.map(list, ({ id, business_name }) => ({
          label: business_name,
          value: id,
        })),
      ];
      setOperatorList(dropdownMap);
    } catch (err) {
      console.log(err);
    } finally {
      setOperatorListLoading(false);
    }
  };

  const fetchVM = async (selectedOperator, selectedArea, selectedLocation) => {
    try {
      setVmListLoading(true);
      setVmList([]);
      const params = {
        all: true,
        ordering: "vm_name",
      };
      if (selectedOperator && selectedOperator.value !== "all")
        params.company_id = selectedOperator.value;
      if (selectedArea && selectedArea.value !== "all")
        params.area_id = selectedArea.value;
      if (selectedLocation && selectedLocation.value !== "all")
        params.location = selectedLocation.value;
      const { data } = await window.axiosIns("device", {
        params,
      });
      setVmList([
        { label: "All", value: "all" },
        ..._.map(data?.data?.results, ({ id, serial_number, vm_name }) => ({
          label: `${vm_name ? `${vm_name} ||` : ""} ${serial_number}`,
          value: id,
        })),
      ]);
    } catch (err) {
      console.log("err", err);
      setVmListLoading(false);
    } finally {
      setVmListLoading(false);
    }
  };

  const fetchProducts = useCallback(async () => {
    const { data } = await window.axiosIns("products", {
      params: { all: true, operator__id: selectedOperator },
    });
    const list = data?.results;
    setProductList([
      ..._.map(list, ({ id, product_name }) => ({
        label: product_name,
        value: id,
      })),
    ]);
  });
  // useEffect(() => {
  //     fetchProducts()

  // }, [selectedOperator])
  const handleBackDate = () => {
    setEndPreviousYear((prevState) => prevState + 1);
    setSelectedYear((prevState) => prevState - 1);
  };

  const handleNextDate = () => {
    setEndPreviousYear((prevState) => prevState - 1);
    setSelectedYear((prevState) => prevState + 1);
  };

  const fetchAreas = async (selectedOperator) => {
    try {
      setAreaListLoading(true);
      const params = { all: true, ordering: "area_name" };
      if (selectedOperator && selectedOperator.value !== "all")
        params.operator_id = selectedOperator.value;
      const { data } = await window.axiosIns("locations/areas", {
        params,
      });
      let areaList = [
        { label: "All", value: "all" },
        ..._.map(data?.results, ({ area_id, area_name, locations }) => ({
          label: area_name,
          value: area_id,
          locations,
        })),
      ];
      setAreaList(areaList);
      fetchLocations(areaList, null, selectedOperator);
    } catch (err) {
      console.log(err);
      fetchLocations([], null, selectedOperator);
    } finally {
      setAreaListLoading(false);
    }
  };
  const formatFinance = (rawData) => {
    return rawData.map((item) => {
      return {
        total_profit: parseFloat(currencyFormatter(item.total_profit)),
        total_sales: parseFloat(currencyFormatter(item.total_sales)),
        total_tax: parseFloat(currencyFormatter(item.total_tax)),
        quantity: item.quantity,
        date: item.date,
      };
    });
  };

  const fetchAnalytics = async (fetchFor = "all", order = null) => {
    try {
      const params = {
        start_date: getFilterStartDate(startDate),
        end_date: getFilterEndDate(endDate),
        ordering: order ? order : ordering,
        tz: getTimeZoneDifference(),
        _scope: "AND",
      };

      if (selectedVm[0]?.value && selectedVm[0]?.value !== "all") {
        params["device_ids"] = selectedVm
          .map((x) => x.value)
          .filter((x) => x !== "all")
          .join(",");
      }
      if (selectedLocation.value && selectedLocation.value !== "all") {
        params["location_id"] = selectedLocation.value;
      }
      if (selectedArea.value && selectedArea.value !== "all") {
        params["area_id"] = selectedArea.value;
      }
      if (selectedOperator.value && selectedOperator.value !== "all") {
        params["operator_id"] = selectedOperator.value;
      }

      if (fetchFor === "all") {
        setLoadingYearly(true);
        setLoadingDaily(true);
      } else if (fetchFor === "yearly") {
        setLoadingYearly(true)
      } else {
        setLoadingDaily(true)
      }

      const { data } = await window.axiosIns("finance_analytics", {
        params,
      });
      const years = Object.keys(data?.monthly?.[0]);
      setSelectedYear(years[years.length - 1]);
      if (fetchFor === "daily" || fetchFor === "all") {
        const dailyFinance = _.map(data?.daily?.[0], (value, date) => ({
          ...value,
          total_profit: parseFloat(value.total_profit.toFixed(2)),
          total_sales: parseFloat(value.total_sales.toFixed(2)),
          total_tax: parseFloat(value.total_tax.toFixed(2)),
          date: dateFormatter(date),
        }));
        setIsDailyExist(data?.daily?.[1]);
        setDailyFinanceData(formatFinance(dailyFinance));
      }
      if (fetchFor === "yearly" || fetchFor === "all") {
        const yearlyFinance = _.map(
          data?.monthly?.[0]?.[years[years.length - 1]]?.[0],
          (value, date) => ({
            ...value,
            total_profit: parseFloat(value.total_profit.toFixed(2)),
            total_sales: parseFloat(value.total_sales.toFixed(2)),
            total_tax: parseFloat(value.total_tax.toFixed(2)),
            date: moment(date).format("MMM-YY"),
          })
        );

        setYearlyFinanceData(formatFinance(yearlyFinance));
        setIsYearlyExist(data?.monthly?.[1]);
      }
      setMonthlyFinanceData(data?.monthly);
    } catch (err) {
    } finally {
      setLoadingYearly(false);
      setLoadingDaily(false);
    }
  };

  const fetchSalesProfit = async (order = null, isProfit = null) => {
    let params = {
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      ordering: order ? order : ordering,
      tz: getTimeZoneDifference(),
    };
    if (selectedArea.value && selectedArea.value !== "all") {
      params.area_id = selectedArea.value;
    }
    if (selectedLocation.value && selectedLocation.value !== "all") {
      params.location_id = selectedLocation.value;
    }
    if (selectedOperator.value && selectedOperator.value !== "all") {
      params.operator_id = selectedOperator.value;
    }
    if (selectedVm && selectedVm[0]?.value !== "all") {
      params.device_id = selectedVm
        .map((x) => x.value)
        .filter((x) => x !== "all")
        .join(",");
    }

    try {
      const res = await window.axiosIns("inventory_statistics/sales_profit", {
        params: params,
      });
      let products = res?.data?.products;
      let product_sales = res?.data?.product_sales;
      let arr = [];
      if (products !== undefined) {
        products.map((product) => {
          arr.push({
            name: product.product_name,
            value: product.product_id,
          });
        });
      }
      setSalesProfitData(product_sales);
      setSalesProfitProductList(arr);
      if (isProfit === null) {
        setSalesProfit(preFormate(res?.data));
        setSalesRevenue(preFormate(res?.data));
      } else if(isProfit) {
        setSalesProfit(preFormate(res?.data));
      } else {
        setSalesRevenue(preFormate(res?.data));
      }
      setLoadingProfit(false);
      setLoadingRevenue(false);
    } catch (error) {
      enqueueSnackbar("Unable to fetch Revenue and Profit data.");
      setLoadingProfit(false);
      setLoadingRevenue(false);
    }
  };

  // useEffect(() => {
  //   selectedVm?.length < 1 && more && setMore(false);
  //   let filterData = [];
  //   if (Array.isArray(selectedVm) && selectedVm.length > 1 && _.filter(selectedVm, (nv) => nv.value === 'all')) {
  //     const indexAll = _.findIndex(selectedVm, (nv) => nv.value === 'all');
  //     if (indexAll === 0) {
  //       filterData = _.filter(selectedVm, (nv) => nv.value !== 'all');
  //     } else if (indexAll !== 0) {
  //       filterData = _.filter(selectedVm, (nv) => nv.value === 'all');
  //     } else if (indexAll === -1) {
  //       filterData = selectedVm;
  //     }
  //     // setSelectedVm(filterData)
  //   }
  // }, [selectedVm])

  useEffect(() => {
    setYearlyFinanceData(
      _.map(monthlyFinanceData?.[0]?.[selectedYear]?.[0], (value, date) => ({
        ...value,
        date: moment(date).format("MMM-YY"),
      }))
    );
  }, [selectedYear]);

  // useEffect(() => {
  //   if(selectedOperator.value === "all") {
  //     fetchAreas();
  //     fetchVM();
  //   }
  // }, [selectedOperator]);

  // useEffect(() => {
  //   if(selectedArea.value === "all") {
  //     fetchLocations();
  //   }
  // }, [selectedArea]);

  // useEffect(() => {
  //   if(selectedLocation.value !== "all") {
  //     fetchVM({location: selectedLocation.value});
  //   } else if (selectedArea.value !== "all") {
  //     fetchVM({area_id: selectedArea.value});
  //   } else if (selectedOperator.value !== "all") {
  //     fetchVM({company_id: selectedOperator.value});
  //   }
  // }, [selectedOperator, selectedArea, selectedLocation]);

  // useEffect(() => {
  //   fetchOperators();
  // }, [])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setLoading(true);

  //       await Promise.all([fetchAnalytics()]);
  //     } catch (err) {
  //       console.log(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   if (
  //     selectedOperator.value ||
  //     selectedArea.value !== "all" ||
  //     selectedLocation.value !== "all" ||
  //     selectedVm[0]?.value !== "all"
  //   ) {
  //     fetchData();
  //   }
  // }, [
  //   startDate,
  //   endDate,
  //   selectedOperator,
  //   selectedVm,
  //   selectedLocation,
  //   selectedArea,
  // ]);

  // useEffect(() => {
  //   if (selectedOperator.value && selectedOperator.value !== "all") {
  //     fetchAreas(selectedOperator.value);
  //   }
  // }, [selectedOperator, fetchAreas]);

  // useEffect(() => {
  //   if (selectedArea.value && selectedArea.value !== "all") {
  //     fetchLocations(selectedArea.value);
  //   }
  // }, [selectedArea, fetchLocations]);

  const preFormate = (rawData) => {
    let data = [];
    try {
      for (const [key, value] of Object.entries(rawData.product_sales)) {
        if (value.length > 0) {
          value.map((val) => {
            let obj = {};
            obj.date = key;
            obj.product_name = val.product_name || "";
            obj.product_sku = val.product_sku || "";
            obj.quantity = val.quantity || "";
            obj.sales = val.sales || "";
            obj.profit = val.profit || "";
            data.push(obj);
          });
        }
      }
    } catch (err) {
      // console.log(err);
    }
    return data;
  };

  // useEffect(() => {
  //   setSalesRevenue(preFormate(totalRevenue))
  //   setSalesProfit(preFormate(totalProfit))
  // }, [totalRevenue, totalProfit])

  useEffect(() => {
    setEnableTaxes(enableTaxesPerVM);
  }, [enableTaxesPerVM]);
  useEffect(() => {
    fetchOperators();
    fetchAreas();
    fetchVM();
  }, []);
  useEffect(() => {
    fetchAnalytics();
    fetchSalesProfit();
  }, [selectedVm, startDate, endDate]);
  const handleOperator = (val) => {
    setSelectedOperator(val);
    fetchAreas(val);
    fetchVM(val);
    setSelectedArea({ value: "all", label: "All" });
    setSelectedLocation({ value: "all", label: "All" });
    setSelectedVm([{ value: "all", label: "All" }]);
  };

  const handleArea = (val) => {
    setSelectedArea(val);
    fetchLocations(areaList, val.value, selectedOperator);
    fetchVM(selectedOperator, val);
    setSelectedLocation({ value: "all", label: "All" });
    setSelectedVm([{ value: "all", label: "All" }]);
  };

  const handleLocation = (val) => {
    setSelectedLocation(val);
    fetchVM(selectedOperator, selectedArea, val);
    setSelectedVm([{ value: "all", label: "All" }]);
  };

  const handleExport = (order) => {
    let params = {
      start_date: getFilterStartDate(startDate),
      end_date: getFilterEndDate(endDate),
      tz: getTimeZoneDifference(),
      export: true,
    };
    if (selectedArea.value && selectedArea.value !== "all") {
      params.area_id = selectedArea.value;
    }
    if (selectedLocation.value && selectedLocation.value !== "all") {
      params.location_id = selectedLocation.value;
    }
    if (selectedOperator.value && selectedOperator.value !== "all") {
      params.operator_id = selectedOperator.value;
    }
    if (selectedVm && selectedVm[0]?.value !== "all") {
      params.device_ids = selectedVm
        .map((x) => x.value)
        .filter((x) => x !== "all")
        .join(",");
    }
    window.axiosIns("/finance_analytics", {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
      params: params
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `VM-Sales-Report-${moment().format("MM-DD-YYYY")}.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {enqueueSnackbar("No Records available for selected date range.")});
  }

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <Grid spacing={4} container className="mb-4">
      <Grid 
  container 
  spacing={1} 
  wrap="nowrap"
  // alignItems="center" 
  justifyContent="space-between" 
  className={classes.selectHeader}
>
  <Grid item xs={12} md={3} lg={3}>
    <Grid container spacing={1} wrap="nowrap">
      <Grid item xs={6}>
        <CustomInputDatePicker
          value={startDate}
          onChange={(date) => setStartDate(date)}
          label="Start Date"
          maxDate={new Date(endDate).setDate(new Date(endDate).getDate() - 1)}
          disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomInputDatePicker
          value={endDate}
          onChange={(date) => setEndDate(date)}
          label="End Date"
          minDate={new Date(startDate).setDate(new Date(startDate).getDate() + 1)}
          maxDate={new Date()}
          disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading}
        />
      </Grid>
    </Grid>
  </Grid>

{/* Operator Dropdown */}
<Grid item xs={6} md={2} lg={1.5}>
  <AsyncAutoComplete
    onChange={handleOperator}
    value={selectedOperator}
    loading={operatorListLoading}
    options={operatorList}
    required
    label="Operator"
    disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading}
  />
</Grid>

{/* Area Dropdown */}
<Grid item xs={6} md={2} lg={1.5}>
  <AsyncAutoComplete
    onChange={handleArea}
    loading={areaListLoading}
    value={selectedArea}
    options={areaList}
    required
    label="Area"
    disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading}
  />
</Grid>

{/* Location Dropdown */}
<Grid item xs={6} md={2} lg={1.5}>
  <AsyncAutoComplete
    onChange={handleLocation}
    value={selectedLocation}
    loading={locationListLoading}
    options={locationList}
    required
    label="Location"
    disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading}
  />
</Grid>


  <Grid item xs={6} md={2} lg={2}>
    <Autocomplete
      multiple
      style={{ width: "100%" }}
      id="tags-outlined"
      value={selectedVm}
      loading={vmListLoading}
      options={vmList || []}
      disabled={operatorListLoading || areaListLoading || locationListLoading || vmListLoading}
      defaultValue={[{ label: "All", value: "all" }]}
      onChange={(event, newValue) => {
        const allPos = _.findIndex(newValue, (x) => x.value === "all");
        if (allPos === 0 && newValue?.length > 1) {
          setSelectedVm(_.filter(newValue, (x) => x.value !== "all"));
        } else if (allPos > 0) {
          setSelectedVm(_.filter(newValue, (x) => x.value === "all"));
        } else {
          setSelectedVm(newValue);
        }
      }}
      getOptionLabel={(option) => option.label}
      renderTags={(tagValue, getTagProps) => (
        tagValue.length < 2 ? tagValue.map((option, index) => (
          <Chip {...getTagProps({ index })} label={`${option.label.slice(0, 8)}...`} />
        )) : (
          <>
            <div>
              {(more ? tagValue : tagValue.slice(0, 1)).map((option, index) => (
                <Chip {...getTagProps({ index })} label={`${option.label.slice(0, 8)}...`} />
              ))}
              {!more && (
                <span
                  style={{ position: "absolute", fontSize: "13px", top: "20px", cursor: "pointer" }}
                  onClick={() => setMore(!more)}
                >{`+${tagValue.length - 1} more`}</span>
              )}
            </div>
            {more && (
              <span
                style={{ position: "static", paddingLeft: "10px", fontSize: "13px", top: "20px", cursor: "pointer" }}
                onClick={() => setMore(!more)}
              >
                show less
              </span>
            )}
          </>
        )
      )}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Vending Machines"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {vmListLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  </Grid>

  <Grid item xs={4} md={1} lg={1}>
    <div className="d-flex" >
      <ExportButton style={{ padding: "16px" }} label={'Export'} onClick={handleExport} />
    </div>
  </Grid>

  <Grid item xs={4} md={1} lg={1} className={classes.toggleWrapper}>
    <Box>
      <ToggleButtonGroup
        size="large"
        value={filterValue}
        exclusive
        onChange={(_, value) => value && setFilterValue(value)}
      >
        <ToggleButton value="graph">
          <Assessment />
        </ToggleButton>
        <ToggleButton value="table">
          <TableChart />
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  </Grid>
</Grid>

        <Grid item xs={6}>
          <Box>
            <Typography variant="h6">Revenue</Typography>
          </Box>
          {filterValue === "graph" ? (
            totalData !== [] ? (
              <MegaLineChart
                fetchSalesProfit={fetchSalesProfit}
                salesProfitData={salesProfitData}
                salesProfitProductList={salesProfitProductList}
                ChartTitle="Revenue"
                vaxisTitle="Revenue($)"
                selectedLocation={selectedLocation.value}
                selectedArea={selectedArea.value}
                startDate={startDate}
                endDate={endDate}
                selectedProduct={selectedProduct}
                selectedOperator={selectedOperator.value}
                selectedVm={selectedVm}
                allProducts={allProducts}
                bgcolor="#EDEDED"
                data={totalData?.product_available}
                loader={loadingRevenue}
              />
            ) : null
          ) : (
            <Box className="mt-4">
              <TableGenerator
                initialSort={"date"}
                sortOrder={"asc"}
                stickyFooter={true}
                stickyHeader
                tableHeight={410}
                disableMinWidth
                sensorTable={true}
                loader={loadingRevenue}
                backendPagination={false}
                rowOnePage={5}
                fields={Salesfields}
                data={salesRevenue}
                handleSortChange={(ordering) => {
                  setOrdering(ordering);
                  fetchSalesProfit(ordering, false);
                }}
                height={440}
                defaultPadding
                stickyTable
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography variant="h6" style={{ display: "flex" }}>
              Monthly Sales
              <Box>
                <ButtonGroup
                  disableElevation
                  size="small"
                  color="primary"
                  style={{ position: "absolute", right: 50 }}
                >
                  <Button
                    disabled={selectedYear <= moment(startDate).year()}
                    onClick={() => handleBackDate()}
                  >
                    <NavigateBeforeIcon />
                  </Button>
                  <Button
                    disabled={selectedYear >= moment(endDate).year()}
                    onClick={() => handleNextDate()}
                  >
                    <NavigateNextIcon />
                  </Button>
                </ButtonGroup>
              </Box>
            </Typography>
          </Box>
          {filterValue === "graph" ? (
            <Box className={classes.graphContainer}>
              {loadingYearly && (
                <CircularProgress className={classes.noDataMessage} />
              )}

              {/** Loading end and the data is not there */}
              {!loadingYearly && !isYearlyExist && (
                <Typography className={classes.noDataMessage} variant="h6">
                  No Data
                </Typography>
              )}
              <BarGraph loading={loadingYearly} data={yearlyFinanceData} enableTaxes={enableTaxes}/>
            </Box>
          ) : (
            <Box className="mt-4">
              <TableGenerator
                initialSort={"date"}
                sortOrder={"asc"}
                stickyFooter={true}
                stickyHeader
                tableHeight={410}
                disableMinWidth
                sensorTable={true}
                loader={loadingYearly}
                backendPagination={false}
                rowOnePage={5}
                fields={fields}
                handleSortChange={(ordering) => {
                  setOrdering(ordering);
                  fetchAnalytics("yearly", ordering);
                }}
                data={yearlyFinanceData}
                height={440}
                defaultPadding
                stickyTable
              />
            </Box>
          )}
        </Grid>

        <Grid style={{ paddingTop: 0 }} item xs={6}>
          <Box>
            <Typography variant="h6">Profit</Typography>
          </Box>
          {filterValue === "graph" ? (
            totalData !== [] ? (
              <MegaLineChart
                hideSettingIcon
                fetchSalesProfit={fetchSalesProfit}
                salesProfitData={salesProfitData}
                salesProfitProductList={salesProfitProductList}
                ChartTitle="Profit"
                vaxisTitle="Profit($)"
                selectedLocation={selectedLocation.value}
                selectedArea={selectedArea.value}
                startDate={startDate}
                endDate={endDate}
                selectedProduct={selectedProduct}
                selectedOperator={selectedOperator.value}
                selectedVm={selectedVm}
                allProducts={allProducts}
                bgcolor="#EDEDED"
                data={totalData?.product_available}
                loader={loadingProfit}
              />
            ) : null
          ) : (
            <Box className="mt-4">
              <TableGenerator
                initialSort={"date"}
                sortOrder={"asc"}
                stickyFooter={true}
                stickyHeader
                tableHeight={410}
                disableMinWidth
                sensorTable={true}
                loader={loadingProfit}
                backendPagination={false}
                rowOnePage={5}
                fields={Profitfields}
                data={salesProfit}
                handleSortChange={(ordering) => {
                  setOrdering(ordering);
                  fetchSalesProfit(ordering, true);
                }}
                height={440}
                defaultPadding
                stickyTable
              />
            </Box>
          )}
        </Grid>

        <Grid style={{ paddingTop: 0 }} item xs={6}>
          <Box>
            <Typography variant="h6">Daily Sales</Typography>
          </Box>
          {filterValue === "graph" ? (
            <Box className={classes.graphContainer}>
              {loadingDaily && (
                <CircularProgress className={classes.noDataMessage} />
              )}

              {/** Loading end and the data is not there */}
              {!loadingDaily && !isDailyExist && (
                <Typography className={classes.noDataMessage} variant="h6">
                  No Data
                </Typography>
              )}
              <BarGraph loading={loadingDaily} data={dailyFinanceData} enableTaxes={enableTaxes}/>
            </Box>
          ) : (
            <Box className="mt-4">
              <TableGenerator
                initialSort={"date"}
                sortOrder={"asc"}
                stickyHeader
                stickyFooter={true}
                tableHeight={410}
                disableMinWidth
                sensorTable={true}
                backendPagination={false}
                loader={loadingDaily}
                rowOnePage={5}
                fields={dailyfields}
                handleSortChange={(ordering) => {
                  setOrdering(ordering);
                  fetchAnalytics("daily", ordering);
                }}
                data={dailyFinanceData}
                defaultPadding
                stickyTable
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default DashboardView;
