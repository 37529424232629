import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "../common/quill-preview.css";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";
import styles from "./styles";
import useFrontendTable from "./useFrontendTable";
import "./styles.css";
import {
  AddButton,
  EditButton,
  DeleteButton,
  ExportButton,
  FileUploadInfoButton,
  FileUploadButton,
} from "../common/Buttons";
import ImagesModal from "./images_info";
import SearchBox from "../common/SearchBox";
import ContentHeader from "../common/ContentHeader";
import TableGenerator from "../common/TableGenerator";
import CrudDialog from "../common/CrudDialog";
import { useSnackbar } from "notistack";
import { Loader } from "components/Common";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import RichTextEditor from "../common/RichTextEditor";
import DOMPurify from "dompurify";
import _ from "lodash";
import {
  handleServerErrors,
  handleMultiFilterSearch,
} from "../../utiles/helpers";
import {
  currencyFormatter,
  getTimeZoneDifference,
  convertUtcToLocal,
  dateFormatter,
  taxFormatter,
} from "utils/helpers";
import InfoModal from "components/Common/AlertDialog/info_dialogue";

const filterLabels = {
  product_sku: "product_sku",
  variant_name: "product_name",
  product_name: "base_product_name",
  operator: "operator__business_name",
  status: "status",
};

const deviceTypeFields = [
  "product_sku",
  "variant_name",
  "product_name",
  "operator",
  "status",
];

const VariantManagement = (props) => {
  const classes = styles();
  const [openVariantModal, setOpenVariantModal] = useState(false);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [descriptionPreview, setDescriptionPreview] = useState("");
  const [deviceTypeList, setDeviceList] = useState([]);
  const [products, setProducts] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [firstPage, setFirstPage] = useState(null);
  const [lastPage, setLastPage] = useState(null);
  const [ordering, setOrdering] = useState("-id");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modulesSelected, setModulesSelected] = useState([]);
  const [filterable, setFilterable] = useState(deviceTypeFields);
  const [query, setQuery] = useState("");
  const [loader, setLoader] = useState(false);
  const [customerList, setCustomersList] = useState([]);
  const [isMutating, setIsMutating] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});
  const [subCategory, setSubCategory] = useState([]);
  const [importError, setImportError] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [crudLoader, setCrudLoader] = useState(false);
  const [variantName, setVariantName] = useState("");
  const [variantNameError, setVariantNameError] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [productSKU, setProductSKU] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [categorySearchQuery, setCategorySearchQuery] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    label: "Enable",
    value: "Enable",
  });
  const [selectedAgeRestriction, setSelectedAgeRestriction] = useState({
    label: "Not Required",
    value: "0",
  });
  const [salePrice, setSalePrice] = useState("");
  const [salePriceError, setSalePriceError] = useState("");
  const [productCost, setProductCost] = useState("");
  const [productCostError, setProductCostError] = useState("");
  const [sellingStartTime, setSellingStartTime] = useState("00:00");
  const [sellingEndTime, setSellingEndTime] = useState("00:00");
  const [purchaseLimit, setPurchaseLimit] = useState("");
  const [purchaseLimitError, setPurchaseLimitError] = useState("");
  const [productTax, setProductTax] = useState("0.0000");
  const [productTaxError, setProductTaxError] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [subtitleError, setSubtitleError] = useState("");
  const [description, setDescription] = useState("");
  const [fileTypeValidation, setFileTypeValidation] = useState("");

  const [numberOfFiles, setNumberOfFiles] = useState(0);
  const [openAddlImagesModal, setOpenAddlImagesModal] = useState(false);
  const [imagesData, setImagesData] = useState(null);
  const [allImages, setAllImages] = useState([]);

  const [barcodeFileName, setBarcodeFileName] = useState("");
  const [barcodeFile, setBarcodeFile] = useState(null);
  const [productPictureName, setProductPictureName] = useState("");
  const [productPicture, setProductPicture] = useState(null);
  const [moreInfoFileName, setMoreInfoFileName] = useState("");
  const [moreInfoFile, setMoreInfoFile] = useState(null);
  const [ingredientsFileName, setIngredientsFileName] = useState("");
  const [ingredientsFile, setIngredientsFile] = useState(null);

  const [checkExtension, setCheckExtension] = useState({
    product_picture: true,
    barcode: true,
    more_info: true,
    ingredients: true,
  });

  const [selectedLabelType1, setSelectedLabelType1] = useState({
    label: "",
    value: "",
  });
  const [selectedLabelType2, setSelectedLabelType2] = useState({
    label: "",
    value: "",
  });
  const [labelTitle1, setLabelTitle1] = useState("");
  const [labelTitle2, setLabelTitle2] = useState("");
  const [labelAttribute1, setLabelAttribute1] = useState("");
  const [labelAttribute2, setLabelAttribute2] = useState("");
  const [hexCode1, setHexCode1] = useState("#000000");
  const [hexCode2, setHexCode2] = useState("#000000");

  const [showTimePeriod, setShowTimePeriod] = useState(false);

  const image_extensions_array = [
    "jpg",
    "jpeg",
    "png",
    "JPG",
    "JPEG",
    "PNG",
    "gif",
    "GIF",
  ];

  const AGE_RESTRICTION_VALUES = [
    { label: "Not Required", value: "0" },
    { label: "18", value: "18" },
    { label: "21", value: "21" },
  ];

  const NO_SALE_DAYS = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ];

  const calculateImageMaxHeight = () => {
    let height = 448;
    if (
      showTimePeriod &&
      subCategory &&
      subCategory["enable_product_selling_time"]
    ) {
      height += 56;
    }
    if (
      subCategory["enable_subcategory"] ||
      subCategory["enable_taxes_per_product"]
    ) {
      height += 56;
    }
    return height + "px";
  };

  const filteredCategories = categoryList.filter((category) =>
    category.category_name
      ?.toLowerCase()
      .includes(categorySearchQuery.toLowerCase())
  );

  const { tableData, setTableData } = useFrontendTable();

  const current_user = useSelector((state) => state.userReducer.current_user);
  const { enqueueSnackbar } = useSnackbar();

  const fields = [
    { key: "id", columnName: "ID", label: "ID", type: "text", visible: true },
    {
      key: "product_sku",
      columnName: "Product SKU",
      label: "Product SKU",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value || "---",
    },
    {
      key: "product_name",
      columnName: "Variant Name",
      label: "Variant Name",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
    },

    {
      key: "operator_name",
      columnName: "Operator",
      label: "Operator",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
    },
    {
      key: "product_picture",
      columnName: "Picture",
      width: 100,
      label: "Product Picture",
      type: "file",
      form: true,
      disableSorting: true,
      visible: true,
      render: (_, values) =>
        values.product_picture && (
          <div
            style={{
              height: 64,
              width: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.product_picture})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "barcode_of_product",
      columnName:
        subCategory && subCategory["change_barcode_to_desc_img"]
          ? "Description Image"
          : "BarCode",
      width: 100,
      label:
        subCategory && subCategory["change_barcode_to_desc_img"]
          ? "Description Image"
          : "BarCode",
      type: "file",
      form: true,
      disableSorting: true,
      visible: (subCategory && subCategory["enable_barcode"]) ?? true,
      render: (_, values) =>
        values.barcode_of_product && (
          <div
            style={{
              height: 64,
              width: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.barcode_of_product})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "more_info_img",
      columnName: "More Info",
      width: 100,
      label: "More Info",
      type: "file",
      form: true,
      visible: (subCategory && subCategory["enable_info_img"]) || false,
      disableSorting: true,
      render: (_, values) =>
        values.more_info_img && (
          <div
            style={{
              height: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.more_info_img})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "ingredients_img",
      columnName: "Ingredients",
      width: 100,
      label: "Ingredients",
      type: "file",
      form: true,
      visible: (subCategory && subCategory["enable_ingredient_img"]) || false,
      disableSorting: true,
      render: (_, values) =>
        values.ingredients_img && (
          <div
            style={{
              height: 64,
            }}
          >
            <div
              style={{
                backgroundImage: `url(${values.ingredients_img})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
              }}
            ></div>
          </div>
        ),
    },
    {
      key: "base_product_name",
      label: "Product",
      columnName: "Product",
      type: "autocomplete",
      required: true,
      show: true,
      freeSolo: false,
      options: products.length
        ? [...products.map((x) => ({ label: x.product_name, value: x.id }))]
        : [{ label: "", value: null }],
      visible: true,
      render: (value) => value ?? "---",
    },
    {
      key: "variant_attribute_1",
      columnName: "Variant Attribute 1",
      type: "text",
      visible: true,
      render: (value, rowValues) => {
        return rowValues?.label_type_1 === "color" ? (
          <span style={{ display: "flex" }}>
            <p
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "25px",
                backgroundColor: rowValues?.hex_code_1,
                marginRight: "5px",
              }}
            />
            {value}
          </span>
        ) : (
          value || "---"
        );
      },
    },
    {
      key: "variant_attribute_2",
      columnName: "Variant Attribute 2",
      type: "text",
      visible: true,
      render: (value, rowValues) => {
        return rowValues?.label_type_2 === "color" ? (
          <span style={{ display: "flex" }}>
            <p
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "25px",
                backgroundColor: rowValues?.hex_code_2,
                marginRight: "5px",
              }}
            />
            {value}
          </span>
        ) : (
          value || "---"
        );
      },
    },
    {
      key: "price",
      columnName: "Price ($)",
      visible: true,
      label: "Price ($)",
      type: "text",
      render: (value) => currencyFormatter(value) || "---",
    },

    {
      key: "cost",
      columnName: "Cost($)",
      label: "Cost($)",
      type: "text",
      visible: true,
      render: (value) => currencyFormatter(value) || "---",
    },
    {
      key: "product_tax",
      columnName: "Tax (%)",
      label: "Tax (%)",
      type: "text",
      visible:
        (subCategory && subCategory["enable_taxes_per_product"]) || false,
      render: (value) => taxFormatter(value) ?? "0.0000",
    },
    {
      key: "weight",
      columnName: "Weight in grams",
      label: "Weight (in grams)",
      type: "text",
      visible: true,
      required: true,
      render: (value) => value ?? "---",
      validations: [
        {
          type: "custom",
          value: (fieldValue) => weightValidation(fieldValue),
          message: "Enter a valid number, with upto 3 decimal places",
        },
      ],
    },
    {
      key: "status",
      columnName: "Status",
      label: "Status",
      type: "text",
      visible: true,
      render: (value) => value || "---",
    },
    {
      key: "age_restriction",
      columnName: "Age Restriction",
      label: "Age Restriction",
      type: "text",
      visible: true,
      render: (value) => (value === "0" ? "Not Required" : value ?? "---"),
    },
    {
      key: "package_type",
      columnName: "Package",
      label: "Package",
      type: "text",
      visible: true,
    },
    {
      key: "shelf_life",
      columnName: "Shelf life(days)",
      label: "Shelf life(days)",
      type: "text",
      visible: true,
      render: (value) =>
        value === null || value === undefined ? "---" : value,
    },
    {
      key: "product_description",
      columnName: "Subtitle",
      label: "Subtitle",
      type: "text",
      visible: true,
      render: (value) => (
        <Tooltip title={value} aria-label="description">
          <span>{value ? `${value.substring(0, 30)}...` : "---"}</span>
        </Tooltip>
      ),
    },
    {
      key: "rich_long_description",
      columnName: "Description",
      label: "Description",
      type: "textarea",
      visible: true,
      render: (_, values) =>
        (values.rich_long_description || values.long_description) && (
          <div
            onClick={() => {
              setDescriptionPreview(
                DOMPurify.sanitize(
                  values.rich_long_description || values.long_description
                )
              );
              setOpenDescriptionModal(true);
            }}
            style={{
              fontStyle: "italic",
              cursor: "pointer",
              color: "#0000EE",
              textDecoration: "underline",
            }}
          >
            Preview Description
          </div>
        ),
    },
    {
      key: "created_at",
      columnName: "Creation Time",
      label: "Creation Time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
    },
    {
      key: "updated_at",
      columnName: "Updated time",
      label: "Updated time",
      type: "text",
      visible: true,
      render: (value) => dateFormatter(convertUtcToLocal(value), true),
    },
  ];

  const handleClose = () => {
    setOpenVariantModal(false);
    setModulesSelected([]);
    setTableData([]);
    setShowTimePeriod(false);
    setVariantName("");
    setVariantNameError("");
    setProductSKU("");
    setSelectedCategories([]);
    setSelectedDays([]);
    setSelectedProduct(null);
    setSelectedOperator(null);
    setSelectedStatus({
      label: "Enable",
      value: "Enable",
    });
    setSelectedLabelType1({
      label: "",
      value: "",
    });
    setSelectedLabelType2({
      label: "",
      value: "",
    });
    setLabelTitle1("");
    setLabelTitle2("");
    setLabelAttribute1("");
    setLabelAttribute2("");
    setHexCode1("#000000");
    setHexCode2("#000000");
    setSelectedAgeRestriction({
      label: "Not Required",
      value: "0",
    });
    setSalePrice("");
    setSalePriceError("");
    setProductCost("");
    setProductCostError("");
    setSellingStartTime("00:00");
    setSellingEndTime("00:00");
    setPurchaseLimit("");
    setPurchaseLimitError("");
    setProductTax("0.0000");
    setProductTaxError("");
    setSubtitle("");
    setSubtitleError("");
    setDescription("");
    setNumberOfFiles(0);
    setImagesData(null);
    setAllImages([]);
    setBarcodeFile(null);
    setBarcodeFileName("");
    setProductPicture(null);
    setProductPictureName("");
    setMoreInfoFile(null);
    setMoreInfoFileName("");
    setIngredientsFile(null);
    setIngredientsFileName("");
    setCheckExtension({
      product_picture: true,
      barcode: true,
      more_info: true,
      ingredients: true,
    });
  };

  const validatePurchaseLimit = (value) => {
    if (value.trim() === "" || value === undefined || value === null) {
      return "";
    }
    if (Number(value) < 1 || Number(value) > 5) {
      return "Quantity should be between 1 to 5";
    }
    return "";
  };

  const validateTax = (value) => {
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100 &&
      (("" + value)?.split(".")?.[1]
        ? value?.split(".")?.[1]?.length <= 4
        : true)
    ) {
      return "";
    }
    return "Enter tax between 0 to 100, only four digits are allowed after decimal";
  };

  const validateVariantName = (value) => {
    if (value.trim() === "") {
      return "Required";
    }
    if (value.length > 70) {
      return "Max length 70";
    }
    return "";
  };

  const validateSubtitle = (value) => {
    if (value.length > 100) {
      return "Max length 100";
    }
    return "";
  };

  const validateWeight = (value) => {
    if (!value) return "";
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 3 : true)
    ) {
      return "";
    }
    return "Enter a valid number, with upto 3 decimal places";
  };

  const validateSalePrice = (value) => {
    if (value.trim() === "") {
      return "Required";
    }
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100000 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 2 : true)
    ) {
      return "";
    }
    return "Enter Sale Price between 0 to 100000, only two digits are allowed after decimal";
  };

  const handleFileChange = async (e, setFileName, setFile, key) => {
    let extension = e[0].name.split(".")[1];
    if (image_extensions_array.includes(extension)) {
      setCheckExtension((prev) => ({ ...prev, [key]: true }));
      setFileName(e[0].name);
      setFile(e[0]);
    } else {
      setFileName("");
      setFile(null);
      setCheckExtension((prev) => ({ ...prev, key: false }));
    }
  };

  const getImages = (field, e) => {
    let images = [];
    for (let i = 0; i < e.length; i++) {
      images.push(e[i]);
    }

    setImagesData(images?.map((x) => [getImageSrc(x), x.name]));
    setNumberOfFiles(images.length);
    setAllImages(images);
  };

  const removeImages = (val) => {
    let images = [];
    for (let i = 0; i < allImages.length; i++) {
      if (typeof allImages[i] === "string") {
        if (allImages[i] !== val[0]) {
          images.push(allImages[i]);
        }
      } else {
        if (allImages[i].name !== val[1]) {
          images.push(allImages[i]);
        }
      }
    }
    setNumberOfFiles(images.length);
    setImagesData(
      images?.map((x) => [
        getImageSrc(x),
        typeof x === "string" ? x.split("/").pop().split("?")[0] : x.name,
      ])
    );
    setAllImages(images);
  };

  const validateProductCost = (value) => {
    if (value.trim() === "") {
      return "Required";
    }
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100000 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 2 : true)
    ) {
      return "";
    }
    return "Enter Product Cost price between 0 to 100000, only two digits are allowed after decimal";
  };

  const getImageSrc = (image) => {
    return typeof image === "string" ? image : URL.createObjectURL(image);
  };

  const isFileTypeValid = (fileName) => {
    if (fileName) {
      const validExtensions = fileTypeValidation.split(",");
      const extension = fileName.split(".").pop();
      return validExtensions.includes("." + extension);
    }
    return true;
  };

  const handleTimeChange = (newTime, setter) => {
    if (newTime) {
      setter(newTime);
    } else {
      setter("00:00");
    }
  };

  const costValidation = (value) => {
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100000 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 2 : true)
    ) {
      return true;
    }
  };

  const taxValidation = (value) => {
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      Number(value) <= 100 &&
      (("" + value)?.split(".")?.[1]
        ? value?.split(".")?.[1]?.length <= 4
        : true)
    ) {
      return true;
    }
  };

  const weightValidation = (value) => {
    if (!value) return true;
    if (
      !isNaN(parseFloat(value)) &&
      Number(value) >= 0 &&
      (value?.split(".")?.[1] ? value?.split(".")?.[1]?.length <= 3 : true)
    ) {
      return true;
    }
  };

  const setup = () => {
    setLoader(true);
    setDeviceList([]);
    setModulesSelected([]);
  };

  const handleRes = (data) => {
    setDeviceList(data.results);

    setNextPage(data.next);
    setPreviousPage(data.previous);
    setFirstPage(data.first);
    setLastPage(data.last);
    setLoader(false);
    setDataCount(data.count);
  };

  const getDeviceType = async (order, max, customPage = page) => {
    const params = {
      ...searchQuery,
      limit: max ? max : rowsPerPage,
      ordering: order ? order : ordering,
      state: "all",
      page: customPage + 1,
      variant: "generic_variants",
    };
    setup();
    const { data } = await window.axiosIns("products", { params });
    handleRes(data);
  };

  function stripHtml(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");

    const blockElements = new Set([
      "P",
      "DIV",
      "BR",
      "LI",
      "BLOCKQUOTE",
      "PRE",
    ]);

    function extractTextWithSpacing(node) {
      let text = "";

      for (let child of node.childNodes) {
        if (child.nodeType === Node.TEXT_NODE) {
          text += child.textContent.trim() + " ";
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          text += extractTextWithSpacing(child);
          if (blockElements.has(child.tagName)) {
            text += "\n";
          }
        }
      }

      return text.replace(/\s+/g, " ").trim();
    }

    return extractTextWithSpacing(doc.body);
  }

  const handleAdd = () => {
    if (variantName === "") {
      enqueueSnackbar("'Variant Name' cannot be empty");
      return;
    }
    if (selectedProduct?.value === "" || selectedProduct === null) {
      enqueueSnackbar("'Product' cannot be empty");
      return;
    }
    if (salePrice === "") {
      enqueueSnackbar("'Sale Price' cannot be empty");
      return;
    }
    if (productCost === "") {
      enqueueSnackbar("'Product Cost' cannot be empty");
      return;
    }
    if (
      variantNameError ||
      salePriceError ||
      productCostError ||
      purchaseLimitError ||
      productTaxError ||
      subtitleError
    ) {
      enqueueSnackbar("Please rectify the above errors");
      return;
    }
    if (
      subCategory &&
      subCategory["enable_product_selling_time"] &&
      selectedAgeRestriction.value !== "0"
    ) {
      if (!sellingStartTime && sellingEndTime) {
        enqueueSnackbar("'Start Time for Selling' cannot be empty");
        return;
      }
      if (sellingStartTime && !sellingEndTime) {
        enqueueSnackbar("'End Time for Selling' cannot be empty");
        return;
      }
    }

    if (selectedLabelType1.value !== "") {
      if (labelTitle1 === "") {
        enqueueSnackbar("Please fill 'Label Title' for Label 1");
        return;
      }
      if (labelAttribute1 === "") {
        enqueueSnackbar("Please fill 'Label Attribute' for Label 1");
        return;
      }
      if (selectedLabelType1.value === "color") {
        if (hexCode1 === "") {
          enqueueSnackbar("Please fill 'Hex Code' for Label 1");
          return;
        }
      }
    }

    if (selectedLabelType2.value !== "") {
      if (labelTitle2 === "") {
        enqueueSnackbar("Please fill 'Label Title' for Label 2");
        return;
      }
      if (labelAttribute2 === "") {
        enqueueSnackbar("Please fill 'Label Attribute' for Label 2");
        return;
      }
      if (selectedLabelType2.value === "color") {
        if (hexCode2 === "") {
          enqueueSnackbar("Please fill 'Hex Code' for Label 2");
          return;
        }
      }
    }

    const fd = new FormData();

    if (allImages && allImages.length) {
      for (let i = 0; i < allImages.length; i++) {
        fd.append(`img${i + 1}`, allImages[i]);
      }
    }
    productPicture && fd.append("product_picture", productPicture);
    barcodeFile && fd.append("barcode_of_product", barcodeFile);
    moreInfoFile && fd.append("more_info_img", moreInfoFile);
    ingredientsFile && fd.append("ingredients_img", ingredientsFile);
    fd.append("rich_long_description", description ?? "");
    fd.append("long_description", stripHtml(description) ?? "");
    fd.append("product_description", subtitle ?? "");
    fd.append(
      "categories",
      selectedCategories.length > 0
        ? JSON.stringify(selectedCategories.map((category) => category.id))
        : "[]"
    );
    fd.append("product_name", variantName);
    fd.append("price", parseFloat(salePrice).toFixed(2));
    fd.append("cost", parseFloat(productCost).toFixed(2));
    fd.append("purchase_limit", purchaseLimit ?? "");
    fd.append("base_product", selectedProduct?.value ?? "");
    fd.append("status", selectedStatus ? selectedStatus?.value : "Enable");
    fd.append(
      "label_type_1",
      selectedLabelType1 ? selectedLabelType1?.value : ""
    );
    fd.append(
      "label_type_2",
      selectedLabelType2 ? selectedLabelType2?.value : ""
    );
    fd.append("label_title_1", labelTitle1 ?? "");
    fd.append("label_title_2", labelTitle2 ?? "");
    fd.append("variant_attribute_1", labelAttribute1 ?? "");
    fd.append("variant_attribute_2", labelAttribute2 ?? "");
    fd.append(
      "hex_code_1",
      selectedLabelType1?.value === "color" ? hexCode1 ?? "" : ""
    );
    fd.append(
      "hex_code_2",
      selectedLabelType2?.value === "color" ? hexCode2 ?? "" : ""
    );
    if (selectedLabelType1?.value !== "" || selectedLabelType2?.value !== "") {
      fd.append("variant_type", "generic_variant");
    } else {
      fd.append("variant_type", "default");
    }
    fd.append("product_sku", productSKU ?? "");
    fd.append("operator", selectedOperator?.value ?? "");
    fd.append("age_restriction", selectedAgeRestriction?.value ?? "0");
    fd.append("product_tax", productTax ?? "0.0000");
    fd.append(
      "no_sale_days",
      (selectedDays &&
        selectedDays.length &&
        selectedDays.map((days) => days.value)) ||
        "[]"
    );
    selectedAgeRestriction?.value !== "0" &&
      sellingStartTime &&
      fd.append("selling_start_time", sellingStartTime ?? "");
    selectedAgeRestriction?.value !== "0" &&
      sellingEndTime &&
      fd.append("selling_end_time", sellingEndTime ?? "");
    let languageVariants = {};
    if (tableData) {
      tableData.forEach(
        (element) =>
          (languageVariants[element.language] = {
            product_name: element.product_name,
            product_description: element.product_description,
            long_description: element.long_description,
          })
      );
    }
    fd.append("language_variants", JSON.stringify(languageVariants) ?? {});
    setIsMutating(true);
    setCrudLoader(true);
    window.axiosIns
      .post(`/products`, fd)
      .then(() => {
        setCrudLoader(false);
        enqueueSnackbar("Variant Added successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
        handleClose();
        setDeviceList([]);
        getDeviceType();
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add product. Try again."
          );
        }
        setCrudLoader(false);
        setTableData([]);
        setIsMutating(false);
      });
  };

  const handleEdit = async () => {
    if (variantName === "") {
      enqueueSnackbar("'Variant Name' cannot be empty");
      return;
    }
    if (selectedProduct?.value === "" || selectedProduct === null) {
      enqueueSnackbar("'Product' cannot be empty");
      return;
    }
    if (salePrice === "") {
      enqueueSnackbar("'Sale Price' cannot be empty");
      return;
    }
    if (productCost === "") {
      enqueueSnackbar("'Product Cost' cannot be empty");
      return;
    }
    if (
      variantNameError ||
      salePriceError ||
      productCostError ||
      purchaseLimitError ||
      productTaxError ||
      subtitleError
    ) {
      enqueueSnackbar("Please rectify the above errors");
      return;
    }
    if (
      subCategory &&
      subCategory["enable_product_selling_time"] &&
      selectedAgeRestriction.value !== "0"
    ) {
      if (!sellingStartTime && sellingEndTime) {
        enqueueSnackbar("'Start Time for Selling' cannot be empty");
        return;
      }
      if (sellingStartTime && !sellingEndTime) {
        enqueueSnackbar("'End Time for Selling' cannot be empty");
        return;
      }
    }

    if (selectedLabelType1.value !== "") {
      if (labelTitle1 === "") {
        enqueueSnackbar("Please fill 'Label Title' for Label 1");
        return;
      }
      if (labelAttribute1 === "") {
        enqueueSnackbar("Please fill 'Label Attribute' for Label 1");
        return;
      }
      if (selectedLabelType1.value === "color") {
        if (hexCode1 === "") {
          enqueueSnackbar("Please fill 'Hex Code' for Label 1");
          return;
        }
      }
    }

    if (selectedLabelType2.value !== "") {
      if (labelTitle2 === "") {
        enqueueSnackbar("Please fill 'Label Title' for Label 2");
        return;
      }
      if (labelAttribute2 === "") {
        enqueueSnackbar("Please fill 'Label Attribute' for Label 2");
        return;
      }
      if (selectedLabelType2.value === "color") {
        if (hexCode2 === "") {
          enqueueSnackbar("Please fill 'Hex Code' for Label 2");
          return;
        }
      }
    }

    const fd = new FormData();
    if (allImages && allImages.length) {
      for (let i = 0; i < allImages.length; i++) {
        fd.append(`img${i + 1}`, allImages[i]);
      }
    }
    productPicture &&
      productPicture.type &&
      fd.append("product_picture", productPicture);
    barcodeFile &&
      barcodeFile.type &&
      fd.append("barcode_of_product", barcodeFile);
    ingredientsFile &&
      ingredientsFile.type &&
      fd.append("ingredients_img", ingredientsFile);
    moreInfoFile &&
      moreInfoFile.type &&
      fd.append("more_info_img", moreInfoFile);
    fd.append("product_description", subtitle ?? "");
    fd.append("rich_long_description", description ?? "");
    fd.append("long_description", stripHtml(description) ?? "");
    fd.append(
      "categories",
      selectedCategories.length > 0
        ? JSON.stringify(selectedCategories.map((category) => category.id))
        : "[]"
    );
    fd.append("product_name", variantName);
    fd.append("price", parseFloat(salePrice).toFixed(2));
    fd.append("cost", parseFloat(productCost).toFixed(2));
    fd.append("purchase_limit", purchaseLimit ?? "");
    fd.append(
      "base_product",
      modulesSelected[0].id === selectedProduct?.value
        ? ""
        : selectedProduct?.value
    );
    fd.append("status", selectedStatus ? selectedStatus?.value : "Enable");
    fd.append(
      "label_type_1",
      selectedLabelType1 ? selectedLabelType1?.value : ""
    );
    fd.append(
      "label_type_2",
      selectedLabelType2 ? selectedLabelType2?.value : ""
    );
    fd.append("label_title_1", labelTitle1 ?? "");
    fd.append("label_title_2", labelTitle2 ?? "");
    fd.append("variant_attribute_1", labelAttribute1 ?? "");
    fd.append("variant_attribute_2", labelAttribute2 ?? "");
    fd.append("hex_code_1", hexCode1 ?? "");
    fd.append("hex_code_2", hexCode2 ?? "");
    if (selectedLabelType1?.value !== "" || selectedLabelType2?.value !== "") {
      fd.append("variant_type", "generic_variant");
    } else {
      fd.append("variant_type", "default");
    }
    fd.append("product_sku", productSKU ?? "");
    fd.append("operator", selectedOperator?.value ?? "");
    fd.append("age_restriction", selectedAgeRestriction?.value ?? "0");
    fd.append("product_tax", productTax ?? "0.0000");
    fd.append(
      "no_sale_days",
      (selectedDays &&
        selectedDays.length &&
        selectedDays.map((days) => days.value)) ||
        "[]"
    );
    selectedAgeRestriction?.value !== "0" &&
      sellingStartTime &&
      fd.append("selling_start_time", sellingStartTime ?? "");
    selectedAgeRestriction?.value !== "0" &&
      sellingEndTime &&
      fd.append("selling_end_time", sellingEndTime ?? "");
    let languageVariants = {};
    if (tableData) {
      tableData.forEach(
        (element) =>
          (languageVariants[element.language] = {
            product_name: element.product_name,
            product_description: element.product_description,
            long_description: element.long_description,
          })
      );
    }
    fd.append("language_variants", JSON.stringify(languageVariants) ?? {});
    const moduleId = modulesSelected[0].id;
    setIsMutating(true);
    setCrudLoader(true);
    window.axiosIns
      .patch(`/products/${moduleId}`, fd)
      .then(() => {
        setCrudLoader(false);
        enqueueSnackbar("Variant edited successfully.");
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
        handleClose();
        setDeviceList([]);
        getDeviceType();
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else if (((err.response || {}).data || {}).detail) {
          enqueueSnackbar(((err.response || {}).data || {}).detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not edit product. Try again."
          );
        }
        setCrudLoader(false);
        setTableData([]);
        setIsMutating(false);
      });
  };

  const getProducts = async () => {
    try {
      const { data } = await window.axiosIns("products", {
        params: {
          all: true,
          state: "all",
          ordering: "product_name",
          variant: "base_products",
        },
      });
      setProducts(data?.results);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCompanyList = async () => {
    try {
      const { data } = await window.axiosIns.get("company", {
        params: { all: true, ordering: "business_name" },
      });
      setCustomersList((data.data || {}).results || []);
      setLoader(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getProductSettings = async () => {
    window
      .axiosIns("/products_settings")
      .then(({ data = {} }) => {
        setSubCategory(data.results?.[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategory = () => {
    window
      .axiosIns("/categories", {
        params: { all: true, ordering: "category_name" },
      })
      .then(({ data = {} }) => {
        setCategoryList(data.results);
      })
      .catch((err) => {
        if (err.detail) {
          enqueueSnackbar(err.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not get categories. Try again."
          );
        }
      });
  };

  useEffect(() => {
    getDeviceType();
    fetchCompanyList();
    getProductSettings();
    getCategory();
    getProducts();
    setFileTypeValidation(".mov,.mp4,.MOV,.MP4");
  }, []);

  useEffect(() => {
    if (selectedAgeRestriction.value.toString() === "0") {
      setShowTimePeriod(false);
    } else {
      setShowTimePeriod(true);
    }
  }, [selectedAgeRestriction]);

  const handleFilter = (arr) => {
    setFilterable(arr);
    if (query !== "") {
      let searchFilter = handleMultiFilterSearch(filterLabels, arr, query);
      setup();
      setPage(0);
      window
        .axiosIns("/products", {
          params: {
            ...searchFilter,
            limit: rowsPerPage,
            variant: "generic_variants",
            ordering,
          },
        })
        .then((data = {}) => {
          handleRes(data.data);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const changePage = async (url) => {
    setup();
    const { data } = await window.axiosIns(url);
    handleRes(data);
  };

  const handleSearch = (value) => {
    setQuery(value);
    let searchFilter = {};
    if (value !== "") {
      searchFilter = handleMultiFilterSearch(filterLabels, filterable, value);
    }
    setSearchQuery(searchFilter);
    setup();
    setPage(0);
    window
      .axiosIns("/products", {
        params: {
          ...searchFilter,
          limit: rowsPerPage,
          variant: "generic_variants",
          state: "all",
          ordering,
        },
      })
      .then((data = {}) => {
        handleRes(data.data);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const variantExport = () => {
    let params = {
      ...searchQuery,
      variant: "generic_variants",
      state: "all",
      tz: getTimeZoneDifference(),
    };

    let product_ids = modulesSelected.map((value) => value?.id);
    if (product_ids.length > 0) params["id"] = product_ids.join(",");

    window
      .axiosIns("/products/export", {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
        params: params,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Variants-Management-Summary-${moment().format("MM-DD-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => console.log(error));
  };

  const capitalizeFirstLetter = (word) =>
    word?.[0]?.toUpperCase() + word?.slice(1) || "";

  const getProductInfo = (id) => {
    setCrudLoader(true);
    window
      .axiosIns("/products/" + id)
      .then(({ data }) => {
        setSelectedCategories(
          categoryList.filter((category) =>
            data?.categories.includes(category.id)
          )
        );
        setSalePrice(currencyFormatter(data?.price ?? ""));
        setProductCost(currencyFormatter(data?.cost ?? ""));
        setPurchaseLimit(data?.purchase_limit ?? "");
        setSelectedStatus({ label: data?.status, value: data?.status });
        setSelectedAgeRestriction(
          AGE_RESTRICTION_VALUES.find(
            (age) => age.value === data?.age_restriction
          )
        );
        setProductTax(taxFormatter(data?.product_tax ?? ""));
        const selectedOperator = customerList.find(
          (operator) => operator.id === data?.operator
        );
        setSelectedOperator(
          selectedOperator
            ? {
                label: `${selectedOperator.business_name} || (${selectedOperator.customer_type})`,
                value: selectedOperator.id,
              }
            : null
        );
        const selectedDays =
          data?.no_sale_days[0] === "[]"
            ? []
            : NO_SALE_DAYS.filter((day) =>
                data?.no_sale_days.includes(day.value)
              );
        setSelectedDays(selectedDays);
        setSubtitle(data?.product_description ?? "");
        setDescription(
          data?.rich_long_description || data?.long_description || ""
        );
        setSelectedLabelType1({
          label: data?.label_type_1
            ? capitalizeFirstLetter(data?.label_type_1)
            : "",
          value: data?.label_type_1 || "",
        });
        setSelectedLabelType2({
          label: data?.label_type_2
            ? capitalizeFirstLetter(data?.label_type_2)
            : "",
          value: data?.label_type_2 || "",
        });
        setLabelTitle1(data?.label_title_1);
        setLabelTitle2(data?.label_title_2);
        setCrudLoader(false);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add product. Try again."
          );
        }
        setCrudLoader(false);
      });
  };

  const handleDelete = () => {
    modulesSelected.forEach((val, index) => {
      window.axiosIns
        .delete(`products/${val.id}`)
        .then(() => {
          setDeleteModal(false);
          if (modulesSelected.length === index + 1) {
            if (
              deviceTypeList.length - modulesSelected.length === 0 &&
              page > 0
            ) {
              setPage(page - 1);
              changePage(previousPage);
            } else {
              getDeviceType();
            }
            enqueueSnackbar("Variant deleted successfully.");
          }
        })
        .catch((err) => {
          if (err.detail) {
            enqueueSnackbar(err.detail);
          } else if (err.response.data.detail) {
            enqueueSnackbar(err.response.data.detail);
          } else {
            handleServerErrors(
              err,
              enqueueSnackbar,
              "Could not delete device. Try again."
            );
          }
        });
    });
  };

  const handleClick = (event) => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    const formData = new FormData();
    formData.append("template", fileUploaded);

    setIsMutating(true);
    window.axiosIns
      .post(`/products/import?variant=true`, formData)
      .then((resp) => {
        const {
          data: { data },
        } = resp;
        const errors = [];
        if (data.records_failed > 0) {
          Object.keys(data.failed_entries).forEach((key) => {
            errors.push(`Row ${key} : ${data.failed_entries[key]}`);
          });

          setImportError(errors);
          setIsInfo(true);
        }
        enqueueSnackbar(resp?.data?.message || "Variants Added successfully.", {
          autoHideDuration: 3000,
        });
        setTimeout(() => {
          setIsMutating(false);
        }, 200);
      })
      .catch((err) => {
        if (err.response.data.detail) {
          enqueueSnackbar(err.response.data.detail);
        } else {
          handleServerErrors(
            err,
            enqueueSnackbar,
            "Could not add variant. Try again."
          );
        }
        setIsMutating(false);
      })
      .then(() => {
        getDeviceType();
        setModulesSelected([]);
      });
  };
  const hiddenFileInput = React.useRef(null);

  return (
    <div id="sa-modules-wrapper" className={classes.wrapper}>
      <ContentHeader
        description="
        All Variant are listed here. Variant can be used to associate a product to a particular Variant. This can be used to calculate Variant wise sales and operative statistics. "
      />
      <div className={classes.toolbar}>
        <div className={classes.crudButtons}>
          <AddButton
            className="mr-3"
            label="Add"
            onClick={() => {
              setModulesSelected([]);
              setOpenVariantModal(true);
            }}
            disabled={current_user.type === "SU"}
          />
          <EditButton
            disabled={
              modulesSelected.length !== 1 || current_user.type === "SU"
            }
            className="mr-3"
            label="Edit"
            onClick={() => {
              const row = modulesSelected[0];
              setVariantName(row?.product_name ?? "");
              const selectedProduct = products.find((product) => {
                if (row?.base_product !== null) {
                  return product.id === row?.base_product;
                } else {
                  return product.id === row?.id;
                }
              });
              setSelectedProduct(
                selectedProduct
                  ? {
                      label: selectedProduct.product_name,
                      value: selectedProduct.id,
                    }
                  : null
              );
              setSelectedCategories(
                categoryList.filter((category) =>
                  row?.categories.includes(category.id)
                )
              );
              setProductSKU(row?.product_sku);
              setSalePrice(currencyFormatter(row?.price ?? ""));
              setProductCost(currencyFormatter(row?.cost ?? ""));
              setPurchaseLimit(row?.purchase_limit ?? "");
              setSelectedStatus({ label: row?.status, value: row?.status });
              setSelectedAgeRestriction(
                AGE_RESTRICTION_VALUES.find(
                  (age) => age.value === row?.age_restriction
                )
              );
              setProductTax(taxFormatter(row?.product_tax ?? ""));
              const selectedOperator = customerList.find(
                (operator) => operator.id === row?.operator
              );
              setSelectedOperator(
                selectedOperator
                  ? {
                      label: `${selectedOperator.business_name} || (${selectedOperator.customer_type})`,
                      value: selectedOperator.id,
                    }
                  : null
              );
              const selectedDays =
                row?.no_sale_days[0] === "[]"
                  ? []
                  : NO_SALE_DAYS.filter((day) =>
                      row?.no_sale_days.includes(day.value)
                    );
              setSelectedDays(selectedDays);
              setSubtitle(row?.product_description ?? "");
              setDescription(
                row?.rich_long_description || row?.long_description || ""
              );
              setNumberOfFiles(row?.images.length);
              setImagesData(row?.images);
              setAllImages([]);
              setProductPicture(row?.product_picture);
              setIngredientsFile(row?.ingredients_img);
              setMoreInfoFile(row?.more_info_img);
              setSelectedLabelType1({
                label: row?.label_type_1
                  ? capitalizeFirstLetter(row?.label_type_1)
                  : "",
                value: row?.label_type_1 || "",
              });
              setSelectedLabelType2({
                label: row?.label_type_2
                  ? capitalizeFirstLetter(row?.label_type_2)
                  : "",
                value: row?.label_type_2 || "",
              });
              setLabelTitle1(row?.label_title_1);
              setLabelTitle2(row?.label_title_2);
              setLabelAttribute1(row?.variant_attribute_1);
              setLabelAttribute2(row?.variant_attribute_2);
              setHexCode1(row?.hex_code_1);
              setHexCode2(row?.hex_code_2);
              setOpenVariantModal(true);
            }}
          />
          <DeleteButton
            disabled={
              modulesSelected.length === 0 || current_user.type === "SU"
            }
            className="mr-3"
            label="Delete"
            onClick={() => setDeleteModal(true)}
          />
          {current_user.type !== "SU" && (
            <>
              <ExportButton
                className="mr-3"
                label="Export"
                onClick={() => variantExport()}
              />
              {/* <ImportButton
                className="mr-3"
                label="Import"
                onClick={handleClick}
              />
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              /> */}
            </>
          )}
        </div>
        <div className="d-flex">
          <SearchBox
            placeholder="Search"
            multiple={true}
            query={query}
            onChange={handleFilter}
            fields={deviceTypeFields}
            selectedFields={filterable}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <div className={classes.content}>
        <TableGenerator
          searchQuery={query}
          initialSort={"id"}
          searchColumnsFilter={true}
          fields={fields}
          loader={loader}
          data={deviceTypeList}
          currentPage={page}
          handleSortChange={(ordering) => {
            setOrdering(ordering);
            getDeviceType(ordering);
          }}
          onPageChange={(page, direction) => {
            setPage(page);
            if (direction === "next") {
              changePage(nextPage);
            } else if (direction === "back") {
              changePage(previousPage);
            } else if (direction === "first") {
              changePage(firstPage);
            } else if (direction === "last") {
              changePage(lastPage);
            }
          }}
          backendPagination={true}
          onRowPerPageChange={(rows) => {
            getDeviceType(null, rows, 0);
            setRowsPerPage(rows);
            setPage(0);
          }}
          dataCount={dataCount}
          onChangePage={(page) => console.log(page)}
          selectedRecords={modulesSelected}
          rowOnePage={10}
          onChangeSelected={(modulesSelected) =>
            setModulesSelected(modulesSelected)
          }
        />

        <Dialog
          open={openVariantModal}
          onClose={handleClose}
          maxWidth="lg"
          fullWidth={true}
          style={{ display: "block" }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Loader visible={crudLoader} />
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <DialogTitle style={{ padding: "0" }}>
                  {modulesSelected.length === 0
                    ? "Add Variant"
                    : "Edit Variant"}
                </DialogTitle>
                <DialogContentText>
                  {modulesSelected.length === 0
                    ? "Please fill in the details below."
                    : "Please edit the details below."}
                </DialogContentText>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                padding: "16px 0",
              }}
            >
              <Grid container spacing={2} xs={6}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    required={true}
                    label="Variant Name"
                    disabled={modulesSelected[0]?.id === selectedProduct?.value}
                    value={variantName}
                    error={!!variantNameError}
                    helperText={variantNameError || ""}
                    onChange={(e) => setVariantName(e.target.value)}
                    onBlur={(e) => {
                      setVariantName(e.target.value?.trim());
                      setVariantNameError(validateVariantName(e.target.value));
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    disabled={modulesSelected.length !== 0}
                    options={
                      products.length
                        ? [
                            ...products.map((x) => ({
                              label: x.product_name,
                              value: x.id,
                            })),
                          ]
                        : [{ label: "", value: "" }]
                    }
                    style={{ display: "block" }}
                    value={selectedProduct}
                    onChange={(_, val) => {
                      getProductInfo(val.value);
                      setSelectedProduct(val);
                    }}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        style={{ width: "100%" }}
                        label="Product"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    size="small"
                    options={filteredCategories}
                    getOptionLabel={(option) => option.category_name}
                    value={selectedCategories}
                    disableCloseOnSelect
                    disabled
                    limitTags={4}
                    onChange={(e, val) => setSelectedCategories(val)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Product Categories"
                        placeholder={
                          selectedCategories.length ? "" : "Search categories"
                        }
                        onChange={(e) => setCategorySearchQuery(e.target.value)}
                      />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.id}
                          label={option.category_name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    label="Product SKU"
                    value={productSKU}
                    onChange={(e) => setProductSKU(e.target.value)}
                    onBlur={(e) => {
                      setProductSKU(e.target.value?.trim());
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    onWheel={(event) => event.target.blur()}
                    label="Purchase Limit"
                    value={purchaseLimit}
                    error={!!purchaseLimitError}
                    helperText={purchaseLimitError || ""}
                    onChange={(e) => setPurchaseLimit(e.target.value)}
                    InputLabelProps={{ shrink: !!purchaseLimit }}
                    onBlur={(e) => {
                      setPurchaseLimit(e.target.value?.trim());
                      setPurchaseLimitError(
                        validatePurchaseLimit(e.target.value)
                      );
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    label="Sale Price ($)"
                    required={true}
                    onWheel={(event) => event.target.blur()}
                    value={salePrice}
                    error={!!salePriceError}
                    helperText={salePriceError || ""}
                    onChange={(e) => setSalePrice(e.target.value)}
                    onBlur={(e) => {
                      setSalePrice(e.target.value?.trim());
                      setSalePriceError(validateSalePrice(e.target.value));
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    variant="outlined"
                    size="small"
                    onWheel={(event) => event.target.blur()}
                    label="Product Cost ($)"
                    required={true}
                    value={productCost}
                    error={!!productCostError}
                    helperText={productCostError || ""}
                    onChange={(e) => setProductCost(e.target.value)}
                    onBlur={(e) => {
                      setProductCost(e.target.value?.trim());
                      setProductCostError(validateProductCost(e.target.value));
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    disabled
                    options={[
                      { label: "Enable", value: "Enable" },
                      { label: "Disable", value: "Disable" },
                    ]}
                    style={{ display: "block" }}
                    value={selectedStatus}
                    onChange={(_, val) => setSelectedStatus(val)}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        style={{ width: "100%" }}
                        label="Status"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    required
                    options={AGE_RESTRICTION_VALUES}
                    style={{ display: "block" }}
                    disabled
                    value={selectedAgeRestriction}
                    onChange={(_, val) => setSelectedAgeRestriction(val)}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    getOptionLabel={(option) => option?.label || ""}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={true}
                        style={{ width: "100%" }}
                        label="Age Restriction"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                {subCategory && subCategory["enable_taxes_per_product"] && (
                  <Grid item xs={6}>
                    <TextField
                      type="text"
                      variant="outlined"
                      size="small"
                      label="Tax (%)"
                      disabled
                      value={productTax}
                      error={!!productTaxError}
                      helperText={productTaxError || ""}
                      onChange={(e) => setProductTax(e.target.value)}
                      onBlur={(e) => {
                        setProductTax(e.target.value?.trim());
                        setProductTaxError(validateTax(e.target.value));
                      }}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                )}
                {!(
                  current_user.type === "FO" ||
                  current_user.type === "OP" ||
                  current_user.type === "DU"
                ) && (
                  <Grid item xs={6}>
                    <Autocomplete
                      id="combo-box-demo"
                      size="small"
                      options={customerList.map((value) => ({
                        label: `${value.business_name} || (${value.customer_type})`,
                        value: value.id,
                      }))}
                      style={{ display: "block" }}
                      value={selectedOperator}
                      onChange={(_, val) => setSelectedOperator(val)}
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      getOptionLabel={(option) => option?.label || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: "100%" }}
                          label="Operator"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    size="small"
                    options={NO_SALE_DAYS}
                    disabled
                    getOptionLabel={(option) => option.label}
                    value={selectedDays}
                    disableCloseOnSelect
                    limitTags={4}
                    onChange={(e, val) => setSelectedDays(val)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="No Sale Days"
                        placeholder={selectedDays.length ? "" : "Select days"}
                      />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          key={option.value}
                          label={option.label}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                  />
                </Grid>
                ̦
              </Grid>
              <Grid container xs={6}>
                {productPicture ? (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      maxHeight: calculateImageMaxHeight(),
                      width: "100%",
                      padding: "8px 0",
                      marginLeft: "16px",
                      borderRadius: 5,
                      background: "#f3f3f3",
                      border: "1px solid #ccc",
                    }}
                  >
                    <IconButton
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        setProductPicture(null);
                        setProductPictureName("");
                      }}
                      style={{
                        width: "8px",
                        height: "8px",
                        position: "absolute",
                        top: -15,
                        right: -15,
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                      src={getImageSrc(productPicture)}
                      alt="Preview Image"
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                      marginLeft: "16px",
                      border: "5px dashed #e2e2e2",
                      borderRadius: 5,
                    }}
                  >
                    <div>
                      <FileUploadButton
                        size="small"
                        value={productPicture}
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            setProductPictureName,
                            setProductPicture,
                            "product_picture"
                          )
                        }
                        tabIndex="19"
                        uploaded={productPictureName !== ""}
                        label="Product Picture"
                        style={{ whiteSpace: "nowrap" }}
                        className="mr-2"
                      />
                      <label
                        style={{
                          fontSize: 13,
                          display: "block",
                          color: "#858585",
                        }}
                      >
                        Suggested file types: png, jpg, jpeg, gif
                      </label>
                      {checkExtension?.product_picture ? (
                        <div
                          style={{
                            fontSize: 11,
                            maxWidth: 200,
                            overflowWrap: "break-word",
                            marginTop: 5,
                          }}
                        >
                          {productPictureName}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{
                padding: "8px 0px",
              }}
            >
              <Grid container spacing={2} xs={12}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    variant="outlined"
                    size="small"
                    label="Subtitle"
                    disabled
                    value={subtitle}
                    error={!!subtitleError}
                    helperText={subtitleError || ""}
                    onChange={(e) => setSubtitle(e.target.value)}
                    onBlur={(e) => {
                      setSubtitle(e.target.value?.trim());
                      setSubtitleError(validateSubtitle(e.target.value));
                    }}
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RichTextEditor
                    description={description}
                    setDescription={setDescription}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                xs={12}
                wrap="nowrap"
                style={{
                  padding: "16px 0px 0px",
                }}
              >
                <Grid item xs={12 / 5}>
                  <div>
                    <FileUploadInfoButton
                      tabIndex="20"
                      uploaded={numberOfFiles !== "" && numberOfFiles !== 0}
                      size="small"
                      label="Additional Images"
                      style={{ whiteSpace: "nowrap" }}
                      open={() => setOpenAddlImagesModal(true)}
                      value={imagesData}
                      className="mr-2"
                    />
                    <label
                      style={{
                        fontSize: 13,
                        display: "block",
                        color: "#858585",
                      }}
                    >
                      Suggested file types: png, jpg, jpeg, gif
                    </label>
                    <div
                      style={{
                        fontSize: 11,
                        maxWidth: 200,
                        overflowWrap: "break-word",
                        marginTop: 5,
                      }}
                    >
                      {numberOfFiles
                        ? Number(numberOfFiles) > 1
                          ? `${numberOfFiles} Files uploaded successfully`
                          : `${numberOfFiles} File uploaded successfully`
                        : ""}
                    </div>
                  </div>
                  <ImagesModal
                    title="ADDITIONAL IMAGES"
                    data={imagesData || []}
                    imagesData={allImages}
                    open={openAddlImagesModal}
                    close={() => setOpenAddlImagesModal(false)}
                    onChange={() => {}}
                    fieldValues={{ images: imagesData }}
                    getImages={getImages}
                    removeImages={removeImages}
                    info={true}
                    changeIcon={false}
                  />
                </Grid>
                {subCategory && subCategory["enable_barcode"] && (
                  <Grid item xs={12 / 5}>
                    <FileUploadButton
                      size="small"
                      value={barcodeFile}
                      onChange={(e) =>
                        handleFileChange(
                          e,
                          setBarcodeFileName,
                          setBarcodeFile,
                          "barcode"
                        )
                      }
                      tabIndex="19"
                      uploaded={barcodeFileName !== ""}
                      label={
                        subCategory && subCategory["change_barcode_to_desc_img"]
                          ? "Description Image"
                          : "BarCode"
                      }
                      style={{ whiteSpace: "nowrap" }}
                      className="mr-2"
                    />
                    <label
                      style={{
                        fontSize: 13,
                        display: "block",
                        color: "#858585",
                      }}
                    >
                      Suggested file types: png, jpg, jpeg, gif
                    </label>
                    {checkExtension?.barcode ? (
                      <div
                        style={{
                          fontSize: 11,
                          maxWidth: 200,
                          overflowWrap: "break-word",
                          marginTop: 5,
                        }}
                      >
                        {barcodeFileName}
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                )}
                {subCategory && subCategory["enable_info_img"] && (
                  <Grid item xs={12 / 5}>
                    <FileUploadButton
                      size="small"
                      value={moreInfoFile}
                      onChange={(e) =>
                        handleFileChange(
                          e,
                          setMoreInfoFileName,
                          setMoreInfoFile,
                          "more_info"
                        )
                      }
                      tabIndex="20"
                      uploaded={moreInfoFileName !== ""}
                      label="More Info"
                      style={{ whiteSpace: "nowrap" }}
                      className="mr-2"
                    />
                    <label
                      style={{
                        fontSize: 13,
                        display: "block",
                        color: "#858585",
                      }}
                    >
                      Suggested file types: png, jpg, jpeg, gif
                    </label>
                    {checkExtension.more_info ? (
                      <div
                        style={{
                          fontSize: 11,
                          maxWidth: 200,
                          overflowWrap: "break-word",
                          marginTop: 5,
                        }}
                      >
                        {moreInfoFileName}
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                )}
                {subCategory && subCategory["enable_ingredient_img"] && (
                  <Grid item xs={12 / 5}>
                    <FileUploadButton
                      size="small"
                      value={ingredientsFile}
                      onChange={(e) =>
                        handleFileChange(
                          e,
                          setIngredientsFileName,
                          setIngredientsFile,
                          "ingredients"
                        )
                      }
                      tabIndex="20"
                      uploaded={ingredientsFileName !== ""}
                      label="Ingredients"
                      style={{ whiteSpace: "nowrap" }}
                      className="mr-2"
                    />
                    <label
                      style={{
                        fontSize: 13,
                        display: "block",
                        color: "#858585",
                      }}
                    >
                      Suggested file types: png, jpg, jpeg, gif
                    </label>
                    {checkExtension.ingredients ? (
                      <div
                        style={{
                          fontSize: 11,
                          maxWidth: 200,
                          overflowWrap: "break-word",
                          marginTop: 5,
                        }}
                      >
                        {ingredientsFileName}
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                )}
              </Grid>
              {selectedLabelType1?.value !== "" &&
                selectedLabelType1?.value !== undefined && (
                  <Grid
                    container
                    spacing={2}
                    xs={12}
                    alignItems="flex-end"
                    style={{ paddingTop: "8px" }}
                  >
                    <Grid item xs={3}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{ color: "#858585", padding: "0 0 8px 4px" }}
                        >
                          Label 1 - {selectedLabelType1?.label}
                        </span>
                        <TextField
                          type="text"
                          variant="outlined"
                          size="small"
                          label="Label Title"
                          value={labelTitle1}
                          disabled
                          onChange={(e) => setLabelTitle1(e.target.value)}
                          onBlur={(e) => {
                            setLabelTitle1(e.target.value?.trim());
                          }}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            style: { zIndex: 0 },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        label="Attribute"
                        value={labelAttribute1}
                        required
                        onChange={(e) => setLabelAttribute1(e.target.value)}
                        onBlur={(e) => {
                          setLabelAttribute1(e.target.value?.trim());
                        }}
                        style={{ width: "100%" }}
                      />
                    </Grid>

                    {selectedLabelType1.value === "color" && (
                      <Grid item xs={3}>
                        <TextField
                          type="color"
                          variant="outlined"
                          size="small"
                          label="Hex Color"
                          value={hexCode1}
                          required
                          onChange={(e) => setHexCode1(e.target.value)}
                          onBlur={(e) => {
                            setHexCode1(e.target.value?.trim());
                          }}
                          style={{ width: "100%" }}
                          disabled={selectedLabelType1.value === ""}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              {selectedLabelType2?.value !== "" &&
                selectedLabelType2?.value !== undefined && (
                  <Grid
                    container
                    spacing={2}
                    xs={12}
                    alignItems="flex-end"
                    style={{ paddingTop: "8px" }}
                  >
                    <Grid item xs={3}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{ color: "#858585", padding: "0 0 8px 4px" }}
                        >
                          Label 2 - {selectedLabelType2?.label}
                        </span>
                        <TextField
                          type="text"
                          variant="outlined"
                          size="small"
                          label="Label Title"
                          value={labelTitle2}
                          disabled
                          onChange={(e) => setLabelTitle2(e.target.value)}
                          onBlur={(e) => {
                            setLabelTitle2(e.target.value?.trim());
                          }}
                          style={{ width: "100%" }}
                          InputLabelProps={{
                            style: { zIndex: 0 },
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        type="text"
                        variant="outlined"
                        size="small"
                        label="Attribute"
                        value={labelAttribute2}
                        required
                        onChange={(e) => setLabelAttribute2(e.target.value)}
                        onBlur={(e) => {
                          setLabelAttribute2(e.target.value?.trim());
                        }}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    {selectedLabelType2.value === "color" && (
                      <Grid item xs={3}>
                        <TextField
                          type="color"
                          variant="outlined"
                          size="small"
                          label="Hex Color"
                          value={hexCode2}
                          required
                          onChange={(e) => setHexCode2(e.target.value)}
                          onBlur={(e) => {
                            setHexCode2(e.target.value?.trim());
                          }}
                          style={{ width: "100%" }}
                          disabled={selectedLabelType2.value === ""}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
            </Grid>
            <div
              style={{
                padding: "12px 0",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                  style={{ marginRight: "8px" }}
                >
                  Close
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    modulesSelected.length === 0 ? handleAdd : handleEdit
                  }
                  disabled={isMutating}
                >
                  {modulesSelected.length === 0 ? "Add" : "Save"}
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openDescriptionModal}
          onClose={() => {
            setOpenDescriptionModal(false);
            setDescriptionPreview("");
          }}
          maxWidth="md"
          fullWidth={true}
          style={{ display: "block" }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Grid container>
              <Grid item xs={6}>
                <DialogTitle style={{ padding: "0 0 16px" }}>
                  Description Preview
                </DialogTitle>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <ReactQuill
                    value={descriptionPreview}
                    readOnly={true}
                    theme="bubble"
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  paddingBottom: "12px",
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setOpenDescriptionModal(false);
                      setDescriptionPreview("");
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </Grid>
          </DialogContent>
        </Dialog>
        <CrudDialog
          title="Delete Variant"
          description="Are you sure you want to delete the Variant?"
          okText="Delete"
          onSubmit={() => handleDelete()}
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
        />
        <InfoModal
          title="Variant Import Errors"
          data={importError || []}
          open={isInfo}
          close={() => setIsInfo(false)}
        />
      </div>
    </div>
  );
};
export default withStyles({}, { withTheme: true })(VariantManagement);
