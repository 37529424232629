import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { withTheme } from "@material-ui/core/styles";
import styles from "./../styles";
import { Typography } from "@material-ui/core";
import { AddButton, EditButton, DeleteButton } from "../../../Common/Buttons";
import { CrudDialog, ContentHeader } from "../../../Common";
import { TableGenerator } from "../../../Common";
import { handleServerErrors, mapOrder } from "../../../../helpers";
import { compose } from "redux";
import { connect } from "react-redux";

const RolesApp = (props) => {
    const classes = styles();
    const { enqueueSnackbar } = useSnackbar();

    const [rolesList, setRolesList] = useState([]);
    const [appsList, setAppsList] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [loader, setLoader] = useState(true);
    const [dataCount, setDataCount] = useState(0);
    const [page, setPage] = useState(0);
    const [ordering, setOrdering] = useState("-id");
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginationLinks, setPaginationLinks] = useState({});
    const [showSubmitLoader, setShowSubmitLoader] = useState(false);

    const fields = [
        {
            key: "name",
            label: "Role Name",
            type: "text",
            required: true,
            maxLength: 128,
            visible: true,
        },
        {
            key: "allowed_apps",
            label: "Allowed Apps",
            type: "multiAutoComplete",
            options: appsList.length ? appsList.map(app => ({ label: app.name, value: app.id })):
             [{ label: "", value: null }],
            visible: true,
            show: true,
            freeSolo: false,
            disableClearable: true,
        },
    ];

    const tableFields = [
        {
            key: "id",
            columnName: "ID",
            type: "text",
            visible: true,
            render: (value) => value || "---",
        },
        {
            key: "name",
            columnName: "Role Name",
            type: "text",
            visible: true,
        },
        {
            key: "_allowed_apps",
            columnName: "Allowed Apps",
            type: "text",
            visible: true,
            render: (value) => value ? value.map(app => app.name).join(", ") : "---",
        },
    ];

    const setup = () => {
        setLoader(true);
        setRolesList([]);
        setSelectedRoles([]);
    };

    const handleRes = (data) => {
        setRolesList(data.results || []);
        setDataCount(data.count || 0);
        setPaginationLinks({
            next: data.next,
            previous: data.previous,
            first: data.first,
            last: data.last,
        });
        setLoader(false);
    };

    const fetchRolesFromUrl = (url) => {
        setup();
        window.axiosIns.get(url)
            .then(({ data }) => {
                handleRes(data);
            })
            .catch((err) => {
                setLoader(false);
                handleServerErrors(err, enqueueSnackbar, "Could not get roles. Try again.");
            });
    };

    const fetchRoles = () => {
        setup();
        window.axiosIns("/roles", {
            params: {
                limit: rowsPerPage,
                ordering: mapOrder(ordering),
            },
        })
            .then(({ data }) => {
                handleRes(data);
            })
            .catch((err) => {
                setLoader(false);
                handleServerErrors(err, enqueueSnackbar, "Could not get roles. Try again.");
            });
    };

    const fetchAppsList = () => {
        window.axiosIns("/apps", { params: { all: true, ordering: "name" } })
            .then(({ data }) => {
                setAppsList(data.data.results);
            })
            .catch((err) => {
                handleServerErrors(err, enqueueSnackbar, "Could not get apps. Try again.");
            });
    };

    const addRole = (values, error) => {
        if (error) return;
        setShowSubmitLoader(true);
        window.axiosIns
            .post("/roles", values)
            .then(() => {
                setAddModal(false);
                fetchRoles();
                enqueueSnackbar("Role created successfully.");
            })
            .catch((err) => {
                handleServerErrors(err, enqueueSnackbar, "Could not create role.");
            })
            .finally(() => {
                setShowSubmitLoader(false);
            });
    };

    const editRole = (values, error) => {
        if (error) return;
        setShowSubmitLoader(true);
        const roleId = selectedRoles[0].id;
        window.axiosIns
            .patch(`/roles/${roleId}`, values)
            .then(() => {
                setEditModal(false);
                fetchRoles();
                enqueueSnackbar("Role updated successfully.");
            })
            .catch((err) => {
                handleServerErrors(err, enqueueSnackbar, "Could not update role.");
            })
            .finally(() => {
                setShowSubmitLoader(false);
            });
    };

    const deleteRoles = () => {
        setShowSubmitLoader(true);
        Promise.all(
            selectedRoles.map((role) =>
                window.axiosIns.delete(`/roles/${role.id}`)
            )
        )
            .then(() => {
                enqueueSnackbar("Role(s) deleted successfully.");
                setDeleteModal(false);
                fetchRoles();
            })
            .catch((err) => {
                handleServerErrors(err, enqueueSnackbar, "Could not delete roles.");
            })
            .finally(() => {
                setShowSubmitLoader(false);
            });
    };

    useEffect(() => {
        fetchRoles();
        fetchAppsList();
    }, []);

    return (
        <div id="sa-users-wrapper" className={classes.wrapper}>
            <ContentHeader
                description={
                    <div>
                        <p style={{ margin: 0 }}>
                        Create and manage roles that decide which apps your employees can use. Just set up a role once, and assign it to employees whenever needed.
                        </p>
                    </div>
                }
                title=""
            />

            <div className={classes.toolbar}>
                <div className={classes.crudButtons}>
                    <AddButton label="Add Role" className="mr-3" onClick={() => setAddModal(true)} />
                    <EditButton
                        label="Edit Role"
                        className="mr-3"
                        disabled={selectedRoles.length !== 1}
                        onClick={() => setEditModal(true)}
                    />
                    <DeleteButton
                        label="Delete Role(s)"
                        className="mr-3"
                        disabled={selectedRoles.length === 0}
                        onClick={() => setDeleteModal(true)}
                    />
                </div>
            </div>
            <div className={classes.content}>
                <TableGenerator
                    fields={tableFields}
                    data={rolesList}
                    loader={loader}
                    currentPage={page}
                    dataCount={dataCount}
                    selectedRecords={selectedRoles}
                    onChangeSelected={setSelectedRoles}
                    onPageChange={(newPage, direction) => {
                        setPage(newPage);
                        if (direction === "next") {
                            fetchRolesFromUrl(paginationLinks.next);
                        } else if (direction === "back") {
                            fetchRolesFromUrl(paginationLinks.previous);
                        } else if (direction === "first") {
                            fetchRolesFromUrl(paginationLinks.first);
                        } else if (direction === "last") {
                            fetchRolesFromUrl(paginationLinks.last);
                        }
                    }}
                    onRowPerPageChange={(newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    window.axiosIns("/roles", {
        params: {
            limit: newRowsPerPage,
            ordering: mapOrder(ordering),
            page: 1,
        },
    })
        .then(({ data }) => {
            handleRes(data);
        })
        .catch((err) => {
            handleServerErrors(err, enqueueSnackbar, "Could not get roles. Try again.");
        });
}}
                    handleSortChange={(newOrdering) => {
                        setOrdering(newOrdering);
                        fetchRoles();
                    }}
                />

                <CrudDialog
                    title="Add Role"
                    okText="Add Role"
                    description="Enter role details below."
                    fields={fields}
                    onSubmit={addRole}
                    open={addModal}
                    onClose={() => setAddModal(false)}
                    Mode="Add"
                    showSubmitActionLoader={showSubmitLoader}
                />

                <CrudDialog
                    title="Edit Role"
                    okText="Save"
                    description="Edit role details below."
                    fields={fields}
                    values={selectedRoles[0]}
                    onSubmit={editRole}
                    open={editModal}
                    onClose={() => setEditModal(false)}
                    mode="Edit"
                    showSubmitActionLoader={showSubmitLoader}
                />

                <CrudDialog
                    title="Delete Role(s)"
                    description="Are you sure you want to delete the selected role(s)?"
                    okText="Delete"
                    onSubmit={deleteRoles}
                    fields={[]}
                    open={deleteModal}
                    onClose={() => setDeleteModal(false)}
                    showSubmitActionLoader={showSubmitLoader}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    currentUser: state.userReducer.current_user,
});

export default compose(withTheme, connect(mapStateToProps))(RolesApp);
