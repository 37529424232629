import React, { Component, Suspense, lazy } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { createBrowserHistory } from "history";
import "./assets/bootstrap-v4/bootstrap.min.css";
import "./App.css";
import theme, { themeObj } from "./theme";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { compose } from "redux";
import { auth } from "./services";
import {
  setHostAction,
  setAppTheme,
  setGlobalLoder,
  setAppLogo,
} from "./redux/actions";
import { domainLevelConfig } from "./ui-config";
import SuperAdminRouter from "./components/SuperAdmin/routes";
import BigLoader from "./components/Common/BigLoader";
import AppRouterHoc from "./components/HOC/AppRouterHoc";
import ThemeManager from "./components/MachineAdmin/ThemeManager";
import SettingManager from "./components/MachineAdmin/SettingManager";
import TroubleshotManager from "./components/MachineAdmin/TroubleshotManager";
import KittingPlan from "./components/MachineAdmin/kittingPlan/components/Plan";
import Inventory from "./components/MachineAdmin/Inventory/Products";
import InventoryList from "./components/MachineAdmin/Inventory/InventoryList";
import TemperatureControl from "./components/MachineAdmin/TemperatureControl";
import RestockKittingPlan from "./components/MachineAdmin/kittingPlan/components/Plan/restockKittingPlan";
import Loader from "./components/Common/Loader";
const customHistory = createBrowserHistory();

const FourNotFour = lazy(() => import("./components/FourNotFour"));
const ForgotPassword = lazy(() => import("./components/ForgotPassword"));
const MyMicrowaveMobile = lazy(() => import("./components/MyMicrowaveMobile"));
const Home = lazy(() => import("./components/Home"));
const Login = lazy(() =>
  import(/* WebpackPrefetch: true */ "./components/Login")
);
const LoginMachineAdmin = lazy(() =>
  import("./components/MachineAdmin/LoginMachineAdmin")
);
const MachineDashboard = lazy(() =>
  import("./components/MachineAdmin/MachineDashboard")
);
const Provisioning = lazy(() =>
  import("./components/MachineAdmin/Provisioning")
);
const About = lazy(() => import("./components/About"));
const Terms = lazy(() => import("./components/Terms"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const Help = lazy(() => import("./components/HelpCenter"));

const Products = lazy(() =>
  import("./components/TouchlessVendingMachine/Products")
);
const Cart = lazy(() => import("./components/TouchlessVendingMachine/Cart"));
const ConfirmTouchlessCheckout = lazy(() =>
  import("./components/TouchlessVendingMachine/ConfirmTouchlessCheckout")
);
const TouchlessPayment = lazy(() =>
  import("./components/TouchlessVendingMachine/PaymentScreen")
);
const ThankYouScreen = lazy(() =>
  import("./components/TouchlessVendingMachine/ThankYouScreen")
);
const QrPaymentScreen = lazy(() =>
  import("./components/TouchlessVendingMachine/QrPayment")
);
const DispensingScreen = lazy(() =>
  import("./components/TouchlessVendingMachine/QrPayment/DispensingScreen")
);
const PaymentThankyouScreen = lazy(() =>
  import("./components/TouchlessVendingMachine/QrPayment/ThankyouScreen")
);
const ScanQR = lazy(() =>
  import("./components/TouchlessVendingMachine/ScanQR")
);

const CategoryContainer = lazy(() =>
  import(
    "./components/TouchlessVendingMachine/IGT/Categories/CategoryContainer"
  )
);
const ProductContainer = lazy(() =>
  import("./components/TouchlessVendingMachine/IGT/Products/ProductContainer")
);
const CartContainer = lazy(() =>
  import("./components/TouchlessVendingMachine/IGT/Cart/CartContainer")
);
const PaymentContainer = lazy(() =>
  import("./components/TouchlessVendingMachine/IGT/Payment/Payment")
);
const DispatchContainer = lazy(() =>
  import("./components/TouchlessVendingMachine/IGT/ItemDispach/Dispatch")
);
const DispatchContainerQr = lazy(() =>
  import("./components/TouchlessVendingMachine/IGT/ItemDispach/DispatchQr")
);
const PaymentConfirm = lazy(() =>
  import("./components/TouchlessVendingMachine/IGT/Payment/PaymentQr")
);

const ReceiptDisplay = lazy(() => import("./components/Receipt/Receipt.jsx"));

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const serial_no = urlParams.get("serial_no");
const amount = urlParams.get("amount");
const action = urlParams.get("action");
const session_id = urlParams.get("session_id");

class App extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     theme: theme,
  //   };
  // }

  AppRouteBase = ({ component: Component, layout: Layout, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <Layout history={customHistory}>
              <Component {...props} {...rest} />
            </Layout>
          );
        }}
      />
    );
  };
  AppRoute = AppRouterHoc(this.AppRouteBase);
  renderRoutes() {
    const AppRoute = this.AppRoute;
    const currentUser = this.props.currentUser || {};
    switch (currentUser.type) {
      case "DA":
        return (
          <SuperAdminRouter currentUser={currentUser} AppRoute={AppRoute} />
        );
      case "OP":
        return (
          <SuperAdminRouter currentUser={currentUser} AppRoute={AppRoute} />
        );
      case "SA":
        return (
          <SuperAdminRouter currentUser={currentUser} AppRoute={AppRoute} />
        );
      case "DE":
        return (
          <SuperAdminRouter currentUser={currentUser} AppRoute={AppRoute} />
        );
      case "FO":
        return (
          <SuperAdminRouter currentUser={currentUser} AppRoute={AppRoute} />
        );
      case "EU":
        return (
          <SuperAdminRouter currentUser={currentUser} AppRoute={AppRoute} />
        );
      case "SU":
        return (
          <SuperAdminRouter currentUser={currentUser} AppRoute={AppRoute} />
        );
      case "EM":
        return (
          <SuperAdminRouter currentUser={currentUser} AppRoute={AppRoute} />
        );
      case "DU":
        return (
          <SuperAdminRouter currentUser={currentUser} AppRoute={AppRoute} />
        );
      default:
        if (
          customHistory.location.pathname !== "/machineadmin" &&
          customHistory.location.pathname !== "/login" &&
          !customHistory.location.pathname.includes("forgot-password") &&
          !customHistory.location.pathname.includes("my-microwave-mobile") &&
          !customHistory.location.pathname.includes("webstore") &&
          !customHistory.location.pathname.includes("receipts")
        ) {
          auth.remove_user();
          localStorage.setItem("next", window.location.pathname);
          customHistory.push(`/login?next=${window.location.pathname}`);
        }
    }
  }

  componentDidMount() {
    this.unlisten = customHistory.listen(() => {
      this.addChatScript();
    });
    this.addChatScript();
    const host = window.location.host;
    const hostConfig =
      domainLevelConfig[host] || domainLevelConfig["default"] || {};
    this.props.setHost(host);
    document.title = hostConfig.name;
    const favicon = document.getElementById("favicon");
    if (hostConfig.favicon || hostConfig.logo) {
      favicon.href = hostConfig.favicon ? hostConfig.favicon : hostConfig.logo;
    }

    if (hostConfig.themeColor && !this.props.customeTheme) {
      this.props.setAppTheme({
        primaryColor: hostConfig.themeColor,
        secondaryColor: hostConfig.themeColor,
        customeTheme: false,
      });
    }
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  addChatScript() {
    const existingScript = document.getElementById("ze-snippet");
    const path = customHistory.location.pathname;

    if (
      path === "/login" ||
      path === "/help" ||
      path === "/about" ||
      path === "/term" ||
      path === "/policy" ||
      path === "/machineadmin" ||
      path === "/machinedashboard" ||
      path === "/provisioning" ||
      path === "/setting" ||
      path === "/troubleshot" ||
      path === "/kitting-plan" ||
      path === "/vm_inventory_list" ||
      path === "/vm_inventory" ||
      path === "/temprature_control" ||
      path === "/restock-kitting-plan" ||
      path === "/secureId" ||
      path === "/simulator" ||
      path.startsWith("/webstore") ||
      path.startsWith("/receipts") ||
      path.startsWith("/forgot-password") ||
      path.startsWith("/apply-theme") ||
      path.startsWith("/my-microwave-mobile")
    ) {
      if (existingScript) {
        existingScript.remove();
        if (window.zE) {
          window.zE.hide();
        }
      }
      return;
    }
    if (!existingScript) {
      const script = document.createElement("script");
      script.id = "ze-snippet";
      script.src =
        "https://static.zdassets.com/ekr/snippet.js?key=65baf311-98c5-4539-a8f5-1caf8a6f046e";
      script.async = true;
      document.body.appendChild(script);

      if (window.zE) {
        window.zE.show();
      }
    }
  }

  render() {
    return (
      <MuiThemeProvider theme={createTheme(this.props.theme)}>
        <Suspense fallback={<BigLoader overlay={false} />}>
          <Router history={customHistory}>
            <div style={{ position: "relative", height: "100%" }}>
              <Switch>
                <Route exact path="/" isPublic component={Home} />
                <Route exact path="/login" isPublic component={Login} />

                {/* Machine Admin UI Routes */}
                <Route
                  exact
                  path="/machineadmin"
                  isPublic
                  component={LoginMachineAdmin}
                />
                <Route
                  exact
                  path="/machinedashboard"
                  component={MachineDashboard}
                />
                <Route exact path="/provisioning" component={Provisioning} />
                <Route exact path="/apply-theme" component={ThemeManager} />
                <Route exact path="/setting" component={SettingManager} />
                <Route
                  exact
                  path="/troubleshot"
                  component={TroubleshotManager}
                />
                <Route exact path="/kitting-plan" component={KittingPlan} />
                <Route
                  exact
                  path="/vm_inventory_list"
                  component={InventoryList}
                />
                <Route exact path="/vm_inventory" component={Inventory} />
                <Route
                  exact
                  path="/temprature_control"
                  component={TemperatureControl}
                />

                <Route
                  exact
                  path="/restock-kitting-plan"
                  component={RestockKittingPlan}
                />
                <Route
                  exact
                  path="/apply-theme/:deviceId"
                  component={ThemeManager}
                />

                {/*Touchless Vending Machine Routes*/}
                <Route exact path="/webstore/qr" isPublic component={ScanQR} />
                <Route
                  exact
                  path="/webstore/products"
                  isPublic
                  component={Products}
                />
                <Route exact path="/webstore/cart" isPublic component={Cart} />
                <Route
                  exact
                  path="/webstore/confirm-checkout"
                  isPublic
                  component={ConfirmTouchlessCheckout}
                />
                <Route
                  exact
                  path="/webstore/final-payment"
                  isPublic
                  component={TouchlessPayment}
                />
                <Route
                  exact
                  path="/webstore/thank-you"
                  isPublic
                  component={ThankYouScreen}
                />
                <Route
                  exact
                  path="/webstore/payment"
                  isPublic
                  component={QrPaymentScreen}
                />
                <Route
                  exact
                  path="/webstore/dispense-products"
                  isPublic
                  component={DispensingScreen}
                />
                <Route
                  exact
                  path="/webstore/payment/thank-you"
                  isPublic
                  component={PaymentThankyouScreen}
                />

                {/*IGT Touchless Vending Machine Routes*/}
                <Route
                  exact
                  path="/webstore/igt/products/category_name"
                  isPublic
                  component={CategoryContainer}
                />
                <Route
                  path="/webstore/igt/products/category_name/*"
                  isPublic
                  component={ProductContainer}
                />
                <Route
                  exact
                  path="/webstore/igt/cart"
                  isPublic
                  component={CartContainer}
                />
                <Route
                  exact
                  path="/webstore/igt/payment"
                  isPublic
                  component={PaymentContainer}
                />
                <Route
                  exact
                  path="/webstore/igt/item-dispatch"
                  isPublic
                  component={DispatchContainer}
                />
                <Route
                  exact
                  path="/webstore/igt/item-dispatch-qr"
                  isPublic
                  component={DispatchContainerQr}
                />
                <Route
                  exact
                  path="/webstore/igt/products/payments:serial_no?/:amount?/:action?/:session_id?"
                  isPublic
                  component={
                    session_id && serial_no && amount && action
                      ? () => <PaymentConfirm />
                      : () => <FourNotFour />
                  }
                />

                {/*E Receipt route*/}
                <Route
                  exact
                  isPublic
                  path="/receipts/:token"
                  component={ReceiptDisplay}
                />

                <Route
                  path="/forgot-password/:token"
                  isPublic
                  component={ForgotPassword}
                />
                <Route
                  path="/my-microwave-mobile/:deviceId"
                  isPublic
                  component={MyMicrowaveMobile}
                />
                <Route
                  exact
                  path="/forgot-password"
                  isPublic
                  component={ForgotPassword}
                />
                <Route exact path="/about" isPublic component={About} />
                <Route exact path="/term" isPublic component={Terms} />
                <Route
                  exact
                  path="/policy"
                  isPublic
                  component={PrivacyPolicy}
                />
                <Route exact path="/help" isPublic component={Help} />

                {this.renderRoutes()}

                <Route path="*">
                  <FourNotFour />
                </Route>
              </Switch>
            </div>
          </Router>
        </Suspense>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.userReducer.current_user,
    host: state.userReducer.host,
    theme: state.userReducer.theme,
    customeTheme: state.userReducer.customeTheme,
    globalLoder: state.userReducer.globalLoder,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHost: (host) => dispatch(setHostAction(host)),
    setAppTheme: (data) => dispatch(setAppTheme(data)),
    setAppLogo: (data) => dispatch(setAppLogo(data)),
    setGlobalLoder: (data) => dispatch(setGlobalLoder(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSnackbar
)(App);
