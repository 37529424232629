import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TableGenerator from "../common/TableGenerator";
import { AddButton, DeleteButton, EditButton } from "../common/Buttons";
import CrudDialog from "../common/CrudDialog";
import _ from "lodash";
import { useSnackbar } from "notistack";

export default function DialogWithTable({
  open,
  onClose,
  tableData = [],
  onAdd,
  onEdit,
  onDelete,
  tableLoading,
  languageList,
  onDuplicate,
  onCancelled,
}) {
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const [productSelected, setProductSelected] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const comboFields = [
    {
      key: "language",
      label: "Langauge",
      visible: true,
      required: true,
      type: "autocomplete",
      options: languageList,
      unclosable: true,
      show: true,
      disabled: editModal,
    },
    {
      key: "product_name",
      label: "Product Name",
      type: "text",
      required: true,
      visible: true,
    },
    {
      key: "product_description",
      label: "Subtitle",
      type: "text",
      required: true,
      visible: true,
      validations: [
        {
          type: "maxLength",
          value: 100,
          message: "Length should be less then 100 Characters",
        },
      ],
    },
    {
      key: "long_description",
      label: "Description",
      type: "text",
      required: true,
      visible: true,
    },
  ];

  const tableFields = [
    {
      key: "id",
      label: "ID",
      type: "text",
      visible: false,
      render: (value) => value ?? "---",
    },
    {
      key: "language",
      columnName: "Language",
      label: "Language",
      type: "text",
      visible: true,
      render: (value, obj) =>
        obj.language_name ||
        languageList.find((x) => x.value === value)?.label ||
        "---",
      disableSorting: true,
    },
    {
      key: "product_name",
      columnName: "Product Name",
      label: "Product Name",
      type: "text",
      required: true,
      visible: true,
      render: (value) => {
        return value ?? "---";
      },
      disableSorting: true,
    },
    {
      key: "product_description",
      columnName: "Subtitle",
      label: "Subtitle",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value ?? "---",
      disableSorting: true,
    },
    {
      key: "long_description",
      columnName: "Description",
      label: "Description",
      type: "text",
      required: true,
      visible: true,
      render: (value) => value ?? "---",
      disableSorting: true,
    },
  ];

  const handleDuplicateLanguages = (values) => {
    let obj = _.find(tableData, { language: values.language });
    if (obj && addModal) {
      enqueueSnackbar(`Variant for this language already exists!`);
    } else if (addModal) {
      onAdd({ ...values, id: values.language });
      setAddModal(false);
    } else if (editModal) {
      onDuplicate(values, obj, selectedIndex, editModal);
      onEdit(values, productSelected[0].id);
      setEditModal(false);
    }
  };

  return (
    <div>
      <Dialog open={open} maxWidth="lg" onClose={onClose}>
        <DialogTitle>Add Language Variants </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill the form fields below.
          </DialogContentText>
          <div>
            <div className="mb-3">
              <AddButton
                onClick={() => {
                  setAddModal(true);
                }}
                className="mr-3"
                label="Add"
              />
              <EditButton
                disabled={productSelected.length !== 1}
                onClick={() => setEditModal(true)}
                className="mr-3"
                label="Edit"
              />
              <DeleteButton
                disabled={productSelected.length <= 0}
                onClick={() => setDeleteModal(true)}
                className="mr-3"
                label="Delete"
              />
            </div>

            <TableGenerator
              fields={tableFields}
              data={tableData}
              backendPagination={false}
              disablePagination={true}
              dataCount={tableData.length}
              selectedRecords={productSelected}
              loader={tableLoading}
              onChangeSelected={(adSelected, index) => {
                setProductSelected(adSelected);
                adSelected.length > 0
                  ? setSelectedIndex(index)
                  : setSelectedIndex(-1);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            onClick={() => {
              onCancelled();
              onClose();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onClose}>
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <CrudDialog
        title="Add Language Variant for Product"
        okText="Save"
        fields={comboFields}
        onFieldChange={(field, value) => {
          //field validations will go here
        }}
        description="Please fill in the details below."
        onSubmit={(values, hasErrors) => {
          handleDuplicateLanguages(values);
        }}
        open={addModal}
        onClose={() => setAddModal(false)}
      />

      <CrudDialog
        title="Update Language Variant for Product"
        okText="Update"
        fields={comboFields}
        description="Please fill in the details below."
        onFieldChange={(field, value) => {
          console.log(value);
        }}
        onSubmit={(values, hasErrors) => {
          handleDuplicateLanguages(values);

          setProductSelected([]);
        }}
        values={productSelected[0]}
        open={editModal}
        onClose={() => setEditModal(false)}
      />

      <CrudDialog
        title="Delete Language Variant for Product"
        description="Are you sure you want to delete the Language Variant?"
        okText="Delete"
        onSubmit={(val) => {
          onDelete(_.map(productSelected, (val) => val.id));
          setProductSelected([]);
          setDeleteModal(false);
        }}
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      />
    </div>
  );
}
