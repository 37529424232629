import { useState, useEffect } from "react";
import _ from "lodash";

function useProducts() {
  const [productsData, setProductsData] = useState([]);
  const [productsSelectData, setProductsSelectData] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);

  const fetchProducts = async () => {
    try {
      setProductsLoading(true);
      const { data } = await window.axiosIns.get("/products", {
        params: { all: true, ordering: "product_name", for_planogram: true },
      });

      setProductsData(data.results);
      setProductsSelectData(
        _.map(data.results, ({ product_name, id, product_sku }) => ({
          label: `${product_sku ? product_sku + " - " : ""}${product_name ?? "---"}`,
          value: id,
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setProductsLoading(false);
    }
  };

  return { productsData, productsSelectData, fetchProducts, productsLoading };
}

export default useProducts;
