import React from "react";
import ReactQuill from "react-quill";
import Quill from "quill";
import "react-quill/dist/quill.snow.css";
import "./quill-editor.css";

const Font = Quill.import("formats/font");
Font.whitelist = [
  "sans-serif",
  "monospace",
  "roboto",
  "times",
  "arial",
  "georgia",
  "courier",
  "comic",
  "verdana",
  "tahoma",
  "impact",
];
Quill.register(Font, true);

function RichTextEditor({ description, setDescription }) {
  return (
    <div style={{ width: "100%" }}>
      <ReactQuill
        value={description}
        onChange={setDescription}
        modules={{
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ font: Font.whitelist }, { size: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ align: [] }],
            ["clean"],
          ],
        }}
      />
    </div>
  );
}

export default RichTextEditor;
